import React, { useState } from "react";
import Axios from "axios";

import { Avatar, CircularProgress, Pagination } from "@mui/material";

export default function Activity() {
  const [activity, setactivity] = React.useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const fetchStats = async () => {
    const { data } = await Axios({
      method: "get",
      url: `/api/activity/dealmaker`,
    });
    setactivity(data.data);
    setCount(data.count);
  };
  React.useEffect(() => {
    fetchStats();
  }, [page]);
  if (activity === null) {
    return <CircularProgress />;
  }

  return (
    <div className="container admin-user-activity">
      <h3>Dealmaker Activity</h3>
      {activity ? (
        <>
          <table>
            <tr>
              <th>Investee</th>
              <th>Investor</th>
              <th>Date</th>
              <th>Status</th>
              <th>Initiated by</th>
            </tr>
            {activity.length > 0 &&
              activity.map((res, i) => (
                <tr key={i} className="my-2 pt-2">
                  <td className=" my-2 pt-2">
                    <Avatar src={res.investee ? res.investee.avatar : ""} />
                    {res.investee
                      ? `${res.investee.name} (${res.investee.firstname})`
                      : ""}
                  </td>
                  <td className=" my-2 pt-2">
                    <Avatar src={res.investor ? res.investor.avatar : ""} />
                    {res.investor
                      ? `${res.investor.name} (${res.investor.firstname})`
                      : ""}
                  </td>
                  <td className=" pt-2">
                    {new Date(res.createdAt).toLocaleTimeString()}{" "}
                    {new Date(res.createdAt).toLocaleDateString()}
                  </td>

                  <td className=" my-2 pt-2">
                    {res.status === 2 ? (
                      <span style={{ color: "red" }}>Requested</span>
                    ) : res.status === 3 ? (
                      <span style={{ color: "red" }}>Declined</span>
                    ) : res.status === 4 ? (
                      <span style={{ color: "green" }}>Accepted</span>
                    ) : res.status === 5 ? (
                      <span style={{ color: "green" }}>Completed</span>
                    ) : res.status === 6 ? (
                      <span style={{ color: "red" }}>Deleted</span>
                    ) : (
                      <span style={{ color: "red" }}>Pending</span>
                    )}
                  </td>
                  <td className=" pt-2">{res.initiatedBy}</td>
                </tr>
              ))}
          </table>
          <div className="pagination">
            <Pagination
              count={count ? Math.ceil(count / 20) : 0}
              page={page}
              onChange={(e, v) => setPage(v)}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
