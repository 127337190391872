import React, { useEffect, useState } from "react";
import Axios from "axios";

import { Avatar, CircularProgress, Pagination } from "@mui/material";

export default function Activity() {
  const [activity, setactivity] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filterVal, setFilter] = useState("all");

  const fetchStats = async () => {
    const { data } = await Axios({
      method: "get",
      url: `/api/activity/valuations?page=${page}&size={20}`,
    });
    setactivity(data.data);
    setCount(data.count);
  };
  useEffect(() => {
    fetchStats();
  }, [page]);
  if (activity === null) {
    return <CircularProgress />;
  }
  return (
    <div className="container admin-user-activity">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="color_ mb-16">Total Reports : {count}</h4>

        <div className="valuation_filter thin_bg">
          <select value={filterVal} onChange={(e) => setFilter(e.target.value)}>
            <option value="all">All</option>
            <option value="Generated">Generated</option>
            <option value="Pending">Pending</option>
          </select>
        </div>
      </div>
      <p className=" font18">Valuation Activity</p>
      {activity.length > 0 ? (
        <>
          <table>
            <tr>
              <th className="">User</th>
              <th className="">Report Title</th>
              <th className=""> Requested</th>
              <th className=""> Generated</th>
              <th className="">Time</th>
            </tr>
            {activity
              .filter((res) => {
                if (filterVal === "all") return res;
                else if (filterVal === "Generated") return res.reportGenerated;
                else if (filterVal === "Pending") return !res.reportGenerated;
              })
              .map((res, i) => (
                <tr key={i} className="my-2 pt-2">
                  <td className=" my-2 pt-2">
                    <Avatar src={res.user ? res.user.avatar : ""} />
                    {res.user ? `${res.user.name} (${res.user.type})` : ""}
                  </td>
                  <td className=" my-2 pt-2">
                    {res.reportGenerated ? res.reportTitle : res.companyName}
                  </td>
                  <td className=" my-2 pt-2 p-4">
                    {res.isRequested ? "Requested" : "Not yet"}
                  </td>

                  <td className=" my-2 pt-2  p-2">
                    {res.reportGenerated ? (
                      <p
                        onClick={() =>
                          window.open(res.reportDownloadLink, "_blank")
                        }
                        style={{
                          cursor: "pointer",
                          color: "green",
                          padding: "10px",
                        }}
                      >
                        Report Link
                      </p>
                    ) : (
                      <p style={{ color: "red" }}>Pending</p>
                    )}
                  </td>

                  <td className=" p-2">
                    {new Date(res.createdAt).toLocaleTimeString()}{" "}
                    {new Date(res.createdAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
          </table>
          <div className="pagination">
            <Pagination
              count={count ? Math.ceil(count / 20) : 0}
              page={page}
              onChange={(e, v) => setPage(v)}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
