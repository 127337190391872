// import { Avatar } from "@mui/material";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import UserComponent from "./UserComponent";
import "./crm.css";
import { Export } from "./Export";
import { Close } from "@mui/icons-material";
export default function CurrentStatus({ setValue }) {
  const [leads, setleads] = useState([]);
  const [filterType, setfilterType] = useState(
    sessionStorage.getItem("filterType")
      ? sessionStorage.getItem("filterType")
      : ""
  );
  // const [filterStatus, setfilterStatus] = useState("");
  const [filterResponsibility, setFilterResponsibility] = useState(
    sessionStorage.getItem("filterResponsibility")
      ? sessionStorage.getItem("filterResponsibility")
      : ""
  );
  const [filterInterRes, setFilterInterRes] = useState(
    sessionStorage.getItem("filterInterRes")
      ? sessionStorage.getItem("filterInterRes")
      : ""
  );

  const [f_prior, setf_prior] = useState(
    sessionStorage.getItem("f_prior") ? sessionStorage.getItem("f_prior") : ""
  );
  const [searchCompany, setsearchCompany] = useState("");
  const [tableView, settableView] = useState("1");
  const getLeads = async () => {
    await Axios({
      method: "get",
      url: "/api/sale/all",
    })
      .then((res) => {
        if (res.data.success) {
          setleads(res.data.data.reverse());
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    getLeads();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("f_prior", f_prior);
    sessionStorage.setItem("filterInterRes", filterInterRes);
    sessionStorage.setItem("filterResponsibility", filterResponsibility);
    sessionStorage.setItem("filterType", filterType);
  }, [f_prior, filterInterRes, filterResponsibility, filterType]);

  return (
    <div className="admin-user-activity ">
      <div className="d-flex mb-2">
        <div className="me-2 w-200px">
          <label>Search Company/Name</label>
          <input
            value={searchCompany}
            className="mb-3 w-100"
            onChange={(e) => setsearchCompany(e.target.value)}
          />
        </div>

        <div className=" me-2 w-200px">
          <label>Responsibility</label>
          <select
            onChange={(e) => setFilterResponsibility(e.target.value)}
            value={filterResponsibility}
            className="mb-3 w-100"
          >
            <option value={""}>All</option>
            <option value={"Susanne Hahn"}>Susanne Hahn</option>
            <option value={"Aparna Veer"}>Aparna Veer</option>
            <option value={"Daniel"}>Daniel Bode</option>
            <option value={"Simon"}>Simon Zieker</option>
            <option value={"Volker"}>Volker Gesman</option>
            <option value={"Fostec Ventures"}>Fostec Ventures</option>
            <option value={"Nikolai Ivansson"}>Nikolai Ivansson</option>
            <option value={"Marc-Oliver Nandy"}>Marc-Oliver Nandy</option>
            <option value={"Kurt Knoesslsdorfer"}>Kurt Knoesslsdorfer</option>
            <option value={"Raj Singh"}>Raj Singh</option>
            <option value={"Rahul Sawlani"}>Rahul Sawlani</option>
            <option value={"None"}>None</option>
          </select>
        </div>

        <div className="me-2 w-200px">
          <label>User Type</label>

          <select
            onChange={(e) => setfilterType(e.target.value)}
            value={filterType}
            className="mb-3 w-100"
          >
            <option value={""}>All</option>
            <option value={"Startup"}>Startup</option>
            <option value={"Investor"}>Fund Company (VC)</option>
            <option value={"Solo Investor"}>Investor</option>
            <option value={"Corporate"}>Corporate</option>
            <option value={"Academia"}>Academia</option>
          </select>
        </div>
        <div className="me-2 w-200px">
          <label>Priority</label>
          <select
            onChange={(e) => setf_prior(e.target.value)}
            value={f_prior}
            className="mb-3 w-100"
          >
            <option value={""}>All</option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
          </select>
        </div>
        <div className="col-1 w-200px">
          <label>Internal Resp.</label>
          <select
            onChange={(e) => setFilterInterRes(e.target.value)}
            value={filterInterRes}
            className="mb-3 w-100"
          >
            <option value={""}>All</option>
            <option value={"Susanne Hahn"}>Susanne Hahn</option>
            <option value={"Aparna Veer"}>Aparna Veer</option>
            <option value={"Daniel"}>Daniel Bode</option>
            <option value={"Simon"}>Simon Zieker</option>
            <option value={"Volker"}>Volker Gesman</option>
            <option value={"Fostec Ventures"}>Fostec Ventures</option>
            <option value={"Nikolai Ivansson"}>Nikolai Ivansson</option>
            <option value={"Marc-Oliver Nandy"}>Marc-Oliver Nandy</option>
            <option value={"Kurt Knoesslsdorfer"}>Kurt Knoesslsdorfer</option>
            <option value={"Raj Singh"}>Raj Singh</option>
            <option value={"Rahul Sawlani"}>Rahul Sawlani</option>
            <option value={"Nayana Yalawatti"}>Nayana Yalawatti</option>
            <option value={"None"}>None</option>
          </select>
        </div>
        <div className="col-1 ms-auto me-2">
          <label>View</label>
          <select
            onChange={(e) => settableView(e.target.value)}
            value={tableView}
            className="mb-3 w-100"
          >
            <option value="1">View 1</option>
            <option value="2">View 2</option>
          </select>
        </div>
      </div>
      <div className="d-flex ">
        {filterResponsibility && (
          <p className=" p_chip mb-0">
            {filterResponsibility} &nbsp;{" "}
            <span
              className="pointer"
              onClick={() => setFilterResponsibility("")}
            >
              {" "}
              <Close />
            </span>
          </p>
        )}
        {f_prior && (
          <p className=" p_chip mb-0">
            {f_prior} &nbsp;{" "}
            <span className="pointer" onClick={() => setf_prior("")}>
              {" "}
              <Close />
            </span>
          </p>
        )}
        {filterType && (
          <p className=" p_chip mb-0">
            {filterType} &nbsp;{" "}
            <span className="pointer" onClick={() => setfilterType("")}>
              {" "}
              <Close />
            </span>
          </p>
        )}
        {filterInterRes && (
          <p className=" p_chip mb-0">
            {filterInterRes} &nbsp;{" "}
            <span className="pointer" onClick={() => setFilterInterRes("")}>
              {" "}
              <Close />
            </span>
          </p>
        )}
      </div>
      <div className="d-flex color-index">
        <p>Platform</p>
        &nbsp;
        <span className="m-0 primary box_20 "></span>
        &nbsp; &nbsp;
        <p>Service</p>
        &nbsp;
        <span className="m-0 warning box_20 "></span>
        &nbsp; &nbsp;
        <p>Both</p>
        &nbsp;
        <span className="m-0 secondary box_20 "></span>
        &nbsp; &nbsp;
        <p>Incubation Tool</p>&nbsp;
        <p className="m-0  blueBorder">&nbsp;I&nbsp;</p>
        &nbsp; &nbsp;
        <p>Association</p>&nbsp;
        <p className="m-0  redborder">A</p>
        <div className="ms-auto">
          <Export apiData={leads} />
        </div>
      </div>

      {/* {tableView && tableView === "2" ? (
        <table style={{ width: "100%" }}>
          <tr>
            <th>User Type</th>
            <th>Name</th>
            <th>Contact Person</th>
            <th>Email</th>
            <th>Status</th>
            <th>Note</th>
          </tr>
          {leads &&
            leads.length > 0 &&
            leads
              .filter((lead) => {
                if (filterStatus === "") return lead;
                else if (lead.status === filterStatus) return lead;
              })
              .filter((lead) => {
                if (filterType === "") return lead;
                else if (lead.userType === filterType) return lead;
              })
              .filter((lead) => {
                if (filterResponsibility === "") return lead;
                else if (lead.responsibility === filterResponsibility)
                  return lead;
              })
              .map((lead, i) => (
                <tr key={i}>
                  <td className="px-2">{lead.userType}</td>
                  <td>
                    <Avatar
                      src={lead.avatar}
                      sx={{ width: 50, height: 50, padding: 0 }}
                      // style={{ margin: 0 }}
                    />{" "}
                    <p
                      className="bold mt-2"
                      //   style={{ width: "fit-content" }}
                    >
                      {lead.name}
                    </p>
                  </td>
                  <td>{lead.contactName}</td> <td>{lead.email}</td>
                  <td className="px-2">{lead.status}</td>
                  <td className="px-2">{lead.note}</td>
                </tr>
              ))}
        </table>
      ) : ( */}
      {tableView === "1" ? (
        <div className="crm_table">
          <table style={{ width: "2400px" }}>
            <tr className="crm_table_row">
              <th>New Lead</th>
              <th>1st Contact</th>
              <th>Initial Meet</th>
              <th>Test Account</th>
              <th>Offer Placed</th>
              <th>Offer Accepted</th>
              <th>Send Invoice</th>
              <th>Invoice Sent</th>
              <th>Money Received</th>
              <th>Customer Nurturing</th>
              {/* <th>2nd Invoice Sent</th>
              <th>2nd Money Received</th> */}
              <th>Declined</th>
              <th>Lost</th>
              <th>No Potential</th>
            </tr>
            <tr style={{ verticalAlign: "baseline" }}>
              <td>
                <div className="row m-0">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "New Lead")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row m-0">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "1st Contact")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "Initial Meet")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "Test Account")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "Offer Placed")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "Offer Accepted")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "Send Invoice")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "Invoice Sent")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "Money Received")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "Customer Nurturing")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              {/* <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "2nd Invoice Sent")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "2nd Money Received")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td> */}
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "Declined")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "Lost")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
              <td>
                <div className="row">
                  {leads &&
                    leads.length > 0 &&
                    leads
                      .filter((lead) => lead.status === "No Potential")
                      .filter((lead) => {
                        if (filterType === "") return lead;
                        else if (lead.userType === filterType) return lead;
                      })
                      .filter((lead) => {
                        if (filterResponsibility === "") return lead;
                        else if (lead.responsibility === filterResponsibility)
                          return lead;
                      })
                      .filter((lead) => {
                        if (f_prior === "") return lead;
                        else if (lead.Priority === f_prior) return lead;
                      })
                      .filter((lead) => {
                        if (searchCompany === "") return lead;
                        else if (
                          String(lead.name)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                        else if (
                          String(lead.contactName)
                            .toLowerCase()
                            .includes(searchCompany.toLowerCase())
                        )
                          return lead;
                      })
                      .filter((lead) => {
                        if (filterInterRes === "") return lead;
                        else if (
                          String(lead.internalRes)
                            .toLowerCase()
                            .includes(filterInterRes.toLowerCase())
                        )
                          return lead;
                      })
                      .map((lead, i) => (
                        <UserComponent
                          key={i}
                          lead={lead}
                          getLeads={getLeads}
                          setValue={setValue}
                        />
                      ))}
                </div>
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <div className="crm_table">
          <table style={{ width: "2400px" }}>
            <tr className="crm_table_row">
              <th>Company</th>

              <th>Contact</th>
              <th>Email</th>
              <th>Status</th>
              <th>Responsible</th>
              <th>Priority</th>
              <th>Type of User</th>

              <th>Expected Revenue</th>
              <th>Last Invoice</th>
              <th>Offer Type</th>

              <th>Notes</th>
              <th>Incubation Tool</th>
            </tr>

            {leads &&
              leads.length > 0 &&
              leads
                // .filter((lead) => lead.status === "New Lead")
                .filter((lead) => {
                  if (filterType === "") return lead;
                  else if (lead.userType === filterType) return lead;
                })
                .filter((lead) => {
                  if (filterResponsibility === "") return lead;
                  else if (lead.responsibility === filterResponsibility)
                    return lead;
                })
                .filter((lead) => {
                  if (f_prior === "") return lead;
                  else if (lead.Priority === f_prior) return lead;
                })
                .filter((lead) => {
                  if (searchCompany === "") return lead;
                  else if (
                    String(lead.name)
                      .toLowerCase()
                      .includes(searchCompany.toLowerCase())
                  )
                    return lead;
                  else if (
                    String(lead.contactName)
                      .toLowerCase()
                      .includes(searchCompany.toLowerCase())
                  )
                    return lead;
                })
                .filter((lead) => {
                  if (filterInterRes === "") return lead;
                  else if (
                    String(lead.internalRes)
                      .toLowerCase()
                      .includes(filterInterRes.toLowerCase())
                  )
                    return lead;
                })
                .map((lead, i) => (
                  <tr style={{ verticalAlign: "baseline" }} key={i}>
                    <td>
                      <p
                        className="bold mt-2"
                        //   style={{ width: "fit-content" }}
                      >
                        {lead.name}
                      </p>
                    </td>
                    <td className="px-2">{lead.contactName}</td>
                    <td className="px-2">{lead.email}</td>
                    <td className="px-2">{lead.status}</td>
                    <td className="px-2">{lead.responsibility}</td>
                    <td className="px-2">{lead.Priority}</td>
                    <td className="px-2">{lead.userType}</td>
                    <td className="px-2">{lead.expectedRevenue}</td>
                    <td className="px-2">{lead.invoiceAmount}</td>
                    <td className="px-2">{lead.offerType}</td>
                    <td className="px-2">{lead.note}</td>
                    <td className="px-2">
                      {lead.isInterestedInIncubationTool}
                    </td>
                  </tr>
                ))}
          </table>
        </div>
      )}

      {/* )} */}
    </div>
  );
}
