const ChangeFormat = (val) => {
  let a = Number(val);
  let isNegative = false;
  if (a < 0) {
    a = Math.abs(a);
    isNegative = true;
  }
  let one = false,
    two = false,
    three = false;
  if (a > 1000) {
    a = a / 1000;
    a = Math.round(a * 1000) / 1000;
    one = true;
  }
  if (a > 1000) {
    a = a / 1000;
    a = Math.round(a * 1000) / 1000;
    two = true;
  }
  if (a > 1000) {
    a = a / 1000;
    a = Math.round(a * 1000) / 1000;
    three = true;
  }
  a = a.toFixed(0);
  if (three) {
    return (isNegative ? -a : a) + " B";
  } else if (two) {
    return (isNegative ? -a : a) + " M";
  } else if (one) {
    return (isNegative ? -a : a) + " K";
  } else {
    if (a === 0) return "";
    else return isNegative ? -a : a;
  }
};

export default ChangeFormat;
