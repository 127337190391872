function compareStrings(a, b) {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : a > b ? 1 : 0;
}

var arr = [
  { index: 0, name: "United States", iso2: "US" },
  { index: 1, name: "Canada", iso2: "CA" },
  { index: 2, name: "Japan", iso2: "JP" },
  { index: 3, name: "China", iso2: "CN" },
  { index: 4, name: "The Netherlands", iso2: "NL" },
  { index: 5, name: "Australia", iso2: "AU" },
  { index: 6, name: "France", iso2: "FR" },
  { index: 7, name: "United Kingdom", iso2: "GB" },
  { index: 8, name: "India", iso2: "IN" },
  { index: 9, name: "Brazil", iso2: "BR" },
  { index: 10, name: "Italy", iso2: "IT" },
  { index: 11, name: "Sweden", iso2: "SE" },
  { index: 12, name: "South Korea", iso2: "KR" },
  { index: 13, name: "Spain", iso2: "ES" },
  { index: 14, name: "Germany", iso2: "DE" },
  { index: 15, name: "Denmark", iso2: "DK" },
  { index: 16, name: "Belgium", iso2: "BE" },
  { index: 17, name: "Turkey", iso2: "TR" },
  { index: 18, name: "Norway", iso2: "NO" },
  { index: 19, name: "Ukraine", iso2: "UA" },
  { index: 20, name: "Hong Kong", iso2: "HK" },
  { index: 21, name: "Israel", iso2: "IL" },
  { index: 22, name: "South Africa", iso2: "ZA" },
  { index: 23, name: "New Zealand", iso2: "NZ" },
  { index: 24, name: "Russian Federation", iso2: "RU" },
  { index: 25, name: "Poland", iso2: "PL" },
  { index: 26, name: "Switzerland", iso2: "CH" },
  { index: 27, name: "Czech Republic", iso2: "CZ" },
  { index: 28, name: "Portugal", iso2: "PT" },
  { index: 29, name: "Indonesia", iso2: "ID" },
  { index: 30, name: "Malaysia", iso2: "MY" },
  { index: 31, name: "Romania", iso2: "RO" },
  { index: 32, name: "Finland", iso2: "FI" },
  { index: 33, name: "Pakistan", iso2: "PK" },
  { index: 34, name: "Singapore", iso2: "SG" },
  { index: 35, name: "Austria", iso2: "AT" },
  { index: 36, name: "Argentina", iso2: "AR" },
  { index: 37, name: "Taiwan", iso2: "TW" },
  { index: 38, name: "United Arab Emirates", iso2: "AE" },
  { index: 39, name: "Vietnam", iso2: "VN" },
  { index: 40, name: "Bangladesh", iso2: "BD" },
  { index: 41, name: "Ireland", iso2: "IE" },
  { index: 42, name: "Chile", iso2: "CL" },
  { index: 43, name: "Kenya", iso2: "KE" },
  { index: 44, name: "Lithuania", iso2: "LT" },
  { index: 45, name: "Cyprus", iso2: "CY" },
  { index: 46, name: "Saudi Arabia", iso2: "SA" },
  { index: 47, name: "Serbia", iso2: "RS" },
  { index: 48, name: "Jordan", iso2: "JO" },
  { index: 49, name: "Peru", iso2: "PE" },
  { index: 50, name: "Hungary", iso2: "HU" },
  { index: 51, name: "Croatia", iso2: "HR" },
  { index: 52, name: "Ghana", iso2: "GH" },
  { index: 53, name: "Colombia", iso2: "CO" },
  { index: 54, name: "Uganda", iso2: "UG" },
  { index: 55, name: "Uruguay", iso2: "UY" },
  { index: 56, name: "Ecuador", iso2: "EC" },
  { index: 57, name: "Panama", iso2: "PA" },
  { index: 58, name: "Mauritius", iso2: "MU" },
  { index: 59, name: "Georgia", iso2: "GE" },
  { index: 60, name: "Iraq", iso2: "IQ" },
  { index: 61, name: "Mexico", iso2: "MX" },
  { index: 62, name: "Lebanon", iso2: "LB" },
  { index: 63, name: "Morocco", iso2: "MA" },
  { index: 64, name: "Tunisia", iso2: "TN" },
  { index: 65, name: "Thailand", iso2: "TH" },
  { index: 66, name: "Philippines", iso2: "PH" },
  { index: 67, name: "Greece", iso2: "GR" },
  { index: 68, name: "Luxembourg", iso2: "LU" },
  { index: 69, name: "Slovakia (Slovak Republic)", iso2: "SK" },
  { index: 70, name: "Estonia", iso2: "EE" },
  { index: 71, name: "Guernsey", iso2: "GG" },
  { index: 72, name: "Nigeria", iso2: "NG" },
  { index: 73, name: "Bulgaria", iso2: "BG" },
  { index: 74, name: "Puerto Rico", iso2: "PR" },
  { index: 75, name: "Egypt", iso2: "EG" },
  { index: 76, name: "Latvia", iso2: "LV" },
  { index: 77, name: "Bolivia", iso2: "BO" },
  { index: 78, name: "Palestinian Territory", iso2: "PS" },
  { index: 79, name: "Dominican Republic", iso2: "DO" },
  { index: 80, name: "Malta", iso2: "MT" },
  { index: 81, name: "Sri Lanka", iso2: "LK" },
  { index: 82, name: "Kuwait", iso2: "KW" },
  { index: 83, name: "Madagascar", iso2: "MG" },
  { index: 84, name: "Guatemala", iso2: "GT" },
  { index: 85, name: "Belize", iso2: "BZ" },
  { index: 86, name: "Ethiopia", iso2: "ET" },
  { index: 87, name: "Iceland", iso2: "IS" },
  { index: 88, name: "Bahrain", iso2: "BH" },
  { index: 89, name: "Cambodia", iso2: "KH" },
  { index: 90, name: "Cameroon", iso2: "CM" },
  { index: 91, name: "Iran", iso2: "IR" },
  { index: 92, name: "Nepal", iso2: "NP" },
  { index: 93, name: "Algeria", iso2: "DZ" },
  { index: 94, name: "Armenia", iso2: "AM" },
  { index: 95, name: "Albania", iso2: "AL" },
  { index: 96, name: "Angola", iso2: "AO" },
  { index: 97, name: "Afghanistan", iso2: "AF" },
  { index: 98, name: "Andorra", iso2: "AD" },
  { index: 99, name: "Antigua and Barbuda", iso2: "AG" },
  { index: 100, name: "Azerbaijan", iso2: "AZ" },
  { index: 101, name: "Bahamas", iso2: "BS" },
  { index: 102, name: "Barbados", iso2: "BB" },
  { index: 103, name: "Belarus", iso2: "BY" },
  { index: 104, name: "Botswana", iso2: "BW" },
  { index: 105, name: "Brunei Darussalam", iso2: "BN" },
  { index: 106, name: "Bosnia and Herzegovina", iso2: "BA" },
  { index: 107, name: "Benin", iso2: "BJ" },
  { index: 108, name: "Bhutan", iso2: "BT" },
  { index: 109, name: "Burkina Faso", iso2: "BF" },
  { index: 110, name: "Central African Republic", iso2: "CF" },
  { index: 111, name: "Burundi", iso2: "BI" },
  { index: 112, name: "Chad", iso2: "TD" },
  {
    index: 113,
    name: "Congo, Democratic Republic of",
    iso2: "CD",
  },
  { index: 114, name: "Congo", iso2: "CG" },
  { index: 115, name: "Dominica", iso2: "DM" },
  { index: 116, name: "Djibouti", iso2: "DJ" },
  { index: 117, name: "El Salvador", iso2: "SV" },
  { index: 118, name: "Gambia", iso2: "GM" },
  { index: 119, name: "Gabon", iso2: "GA" },
  { index: 120, name: "Haiti", iso2: "HT" },
  { index: 121, name: "Honduras", iso2: "HN" },
  { index: 122, name: "Guinea", iso2: "GN" },
  { index: 123, name: "Jamaica", iso2: "JM" },
  { index: 124, name: "Kazakhstan", iso2: "KZ" },
  {
    index: 125,
    name: "Lao People's Democratic Republic",
    iso2: "LA",
  },
  { index: 126, name: "Kyrgyzstan", iso2: "KG" },
  { index: 127, name: "Liechtenstein", iso2: "LI" },
  { index: 128, name: "Lesotho", iso2: "LS" },
  { index: 129, name: "Liberia", iso2: "LR" },
  { index: 130, name: "Libya", iso2: "LY" },
  { index: 131, name: "Malawi", iso2: "MW" },
  { index: 132, name: "Mali", iso2: "ML" },
  { index: 133, name: "Moldova", iso2: "MD" },
  { index: 134, name: "Marshall Islands", iso2: "MH" },
  { index: 135, name: "Maldives", iso2: "MV" },
  { index: 136, name: "Mauritania", iso2: "MR" },
  { index: 137, name: "Namibia", iso2: "NA" },
  { index: 138, name: "Mongolia", iso2: "MN" },
  { index: 139, name: "Montenegro", iso2: "ME" },
  { index: 140, name: "Mozambique", iso2: "MZ" },
  { index: 141, name: "Nicaragua", iso2: "NI" },
  { index: 142, name: "Niger", iso2: "NE" },
  { index: 143, name: "Macedonia", iso2: "MK" },
  { index: 144, name: "Oman", iso2: "OM" },
  { index: 145, name: "Paraguay", iso2: "PY" },
  { index: 146, name: "Papua New Guinea", iso2: "PG" },
  { index: 147, name: "Qatar", iso2: "QA" },
  { index: 148, name: "Rwanda", iso2: "RW" },
  { index: 149, name: "Sao Tome and Principe", iso2: "ST" },
  { index: 150, name: "Saint Lucia", iso2: "LC" },
  { index: 151, name: "Saint Kitts and Nevis", iso2: "KN" },
  { index: 152, name: "Senegal", iso2: "SN" },
  { index: 153, name: "Seychelles", iso2: "SC" },
  { index: 154, name: "Sierra Leone", iso2: "SL" },
  { index: 155, name: "Slovenia", iso2: "SI" },
  { index: 156, name: "Somalia", iso2: "SO" },
  { index: 157, name: "Tajikistan", iso2: "TJ" },
  { index: 158, name: "Syria", iso2: "SY" },
  { index: 159, name: "Sudan", iso2: "SD" },
  { index: 160, name: "Suriname", iso2: "SR" },
  { index: 161, name: "Tanzania", iso2: "TZ" },
  { index: 162, name: "Togo", iso2: "TG" },
  { index: 163, name: "Trinidad and Tobago", iso2: "TT" },
  { index: 164, name: "Timor-Leste", iso2: "TL" },
  { index: 165, name: "Turkmenistan", iso2: "TM" },
  { index: 166, name: "Venezuela", iso2: "VE" },
  { index: 167, name: "Uzbekistan", iso2: "UZ" },
  { index: 168, name: "Zambia", iso2: "ZM" },
  { index: 169, name: "Zimbabwe", iso2: "ZW" },
  { index: 170, name: "Fiji", iso2: "FJ" },
  { index: 171, name: "Costa Rica", iso2: "CR" },
  { index: 172, name: "Myanmar", iso2: "MM" },
  { index: 173, name: "Comoros", iso2: "KM" },
  { index: 174, name: "French Guiana", iso2: "GF" },
  { index: 175, name: "Greenland", iso2: "GL" },
  { index: 176, name: "Cape Verde", iso2: "CV" },
  { index: 177, name: "CÃte d'Ivoire", iso2: "CI" },
  { index: 178, name: "Cayman Islands", iso2: "KY" },
  { index: 179, name: "Anguilla", iso2: "AI" },
  { index: 180, name: "Gibraltar", iso2: "GI" },
  { index: 181, name: "Jersey", iso2: "JE" },
  { index: 182, name: "Isle of Man", iso2: "IM" },
  { index: 183, name: "Yemen", iso2: "YE" },
  { index: 184, name: "San Marino", iso2: "SM" },
  { index: 185, name: "Bermuda", iso2: "BM" },
  { index: 186, name: "Saint BarthÃ©lemy", iso2: "BL" },
  { index: 187, name: "CÃƒÂte d'Ivoire", iso2: "CI" },
  { index: 188, name: "Cuba", iso2: "CU" },
  { index: 189, name: "Guam", iso2: "GU" },
  { index: 190, name: "Solomon Islands", iso2: "SB" },
  { index: 191, name: "Macao", iso2: "MO" },
  { index: 192, name: "Reunion", iso2: "RE" },
  { index: 193, name: "Guyana", iso2: "GY" },
  { index: 194, name: "Guinea-Bissau", iso2: "GW" },
  { index: 195, name: "Equatorial Guinea", iso2: "GQ" },
];

export default arr.sort(function (a, b) {
  return compareStrings(a.name, b.name);
});
