import React, { useState } from "react";
import Axios from "axios";
import { Avatar, CircularProgress, Pagination } from "@mui/material";
import { useAuth } from "../../../../Utils/Auth";

export default function OnlineActivity() {
  const [activity, setactivity] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const { onlineusers } = useAuth();
  const [onlineDetails, setonlineDetails] = useState([]);
  const fetchStats = async () => {
    const { data } = await Axios({
      method: "get",
      url: `/api/admin/online-activity?page=${page}&size=20`,
    });
    setactivity(data.data);
    setCount(data.count);
  };
  const fetchOnlineUsers = async () => {
    const { data } = await Axios({
      method: "post",
      url: `/api/admin/onlineusers`,
      data: { onlineusers },
    });
    setonlineDetails(data.data);
  };
  React.useEffect(() => {
    fetchStats();
  }, [page]);
  React.useEffect(() => {
    fetchOnlineUsers();
  }, [onlineusers]);

  if (activity === null) {
    return <CircularProgress />;
  }
  return (
    <div className="container  admin-user-activity">
      <p className="white font18">Online Activity</p>
      {activity ? (
        <>
          <table>
            <tr>
              <th>User</th>
              <th>Current status</th>
              <th>Last Seen</th>
            </tr>
            {onlineDetails.length > 0 &&
              onlineDetails.map((res, i) => (
                <tr key={i} className="my-2 pt-2">
                  <td className=" my-2 pt-2">
                    <Avatar src={res ? res.avatar : ""} />
                    {res ? res.name : ""}:-{" "}
                    {res ? res.firstname + " " + res.lastname : ""}
                  </td>
                  <td style={{ color: "green" }}>Online</td>
                  <td>
                    {`${new Date(
                      res.lastActiveAt
                    ).toLocaleTimeString()} ${new Date(
                      res.lastActiveAt
                    ).toLocaleDateString()}`}
                  </td>
                </tr>
              ))}
            {activity.length > 0 &&
              activity.map((res, i) => (
                <tr key={i} className="my-2 pt-2">
                  <td className=" my-2 pt-2">
                    <Avatar src={res ? res.avatar : ""} />
                    {res ? res.name : ""}:-{" "}
                    {res ? res.firstname + " " + res.lastname : ""}
                  </td>
                  <td style={{ color: "red" }}>Offline</td>
                  <td>
                    {`${new Date(
                      res.lastActiveAt
                    ).toLocaleTimeString()} ${new Date(
                      res.lastActiveAt
                    ).toLocaleDateString()}`}
                  </td>
                </tr>
              ))}
          </table>
          <div className="pagination">
            <Pagination
              count={count ? Math.ceil(count / 20) : 0}
              page={page}
              onChange={(e, v) => setPage(v)}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
