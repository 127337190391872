import React, { useState } from "react";
import { toast } from "react-toastify";

const Index = () => {
  const [description, setdescription] = useState("");
  const [link, setlink] = useState("");
  const [image, setimage] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (description.length === 0)
      return toast.error("Enter details before sending");
    if (!image) return toast.error("Upload Image before Submitting");
    if (!confirm("Are you sure you want to Send this notification?")) {
      return;
    }
    await fetch("/api/admin/notification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        image,
        adminBroadcast: description,
        linkey: link,
      }),
    });
    setdescription("");
    setlink("");
    setimage(null);
    toast.success("Notification Sent Successfully!");
  };

  const handleUpload = (e) => {
    const image = e.target.files[0];
    const formdata = new FormData();
    formdata.append("image", image);
    if (image.type !== "image/jpeg" && image.type !== "image/png") {
      return toast.error("File Type not Supported");
    }
    if (image.size > 1024 * 1024) {
      return toast.error("File Size too large");
    }

    fetch(`/api/admin/notification/uploadimage`, {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((data) => {
        setimage(data.location);
        toast.success("Picture Uploaded");
      });
  };
  return (
    <div>
      <h1 style={{ color: "#fff" }} className="text-center">
        Send Notifications
      </h1>
      <div className="container">
        <form>
          <div className="form-group">
            <label style={{ color: "#fff" }} htmlFor="description">
              Description of Notification
            </label>
            <textarea
              type="text"
              className="form-control"
              id="description"
              placeholder="Description"
              onChange={(e) => setdescription(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label style={{ color: "#fff" }} htmlFor="description">
              Attach Link to Redirect
            </label>
            <input
              type="text"
              className="form-control"
              id="link"
              placeholder="Link to Redirect"
              onChange={(e) => setlink(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label style={{ color: "#fff" }} htmlFor="image">
              Image for Notification
            </label>
            {image ? (
              <>
                <img
                  src={image}
                  alt="notification"
                  style={{ height: "100px", width: "100px" }}
                />
                <button
                  style={{ color: "black" }}
                  type="button"
                  onClick={() => setimage(null)}
                >
                  Remove Image
                </button>
              </>
            ) : (
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className="form-control-file"
                id="image"
                name="image"
                onChange={handleUpload}
              />
            )}
          </div>
          <br />
          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-primary"
          >
            Send Notification
          </button>
        </form>
      </div>
    </div>
  );
};

export default Index;
