import React, { useState } from "react";
import Axios from "axios";

import { Avatar, CircularProgress, Pagination } from "@mui/material";
import Graphs from "./Graphs";

export default function Activity() {
  const [activity, setactivity] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const fetchStats = async () => {
    const { data } = await Axios({
      method: "get",
      url: `/api/admin/user-activity?page=${page}&size=20`,
    });
    setactivity(data.data);
    setCount(data.count);
  };
  React.useEffect(() => {
    fetchStats();
  }, [page]);
  if (activity === null) {
    return <CircularProgress />;
  }
  return (
    <div className="container  admin-user-activity">
      <Graphs />
      <h3>User Activity</h3>
      {activity ? (
        <>
          <table>
            <tr>
              <th>User</th>
              <th>Time</th>
              <th>Status</th>
              <th>Message</th>
              <th>Type</th>
              <th>Device</th>
            </tr>
            {activity.length > 0 &&
              activity.map((res, i) => (
                <tr key={i} className="my-2 pt-2">
                  <td className=" my-2 pt-2">
                    <Avatar src={res.user_id ? res.user_id.avatar : ""} />
                    {res.user_id ? res.user_id.name : ""}:-{" "}
                    {res.user_id
                      ? res.user_id.firstname + " " + res.user_id.lastname
                      : ""}
                  </td>
                  <td>
                    {new Date(res.updatedAt).toLocaleTimeString()}{" "}
                    {new Date(res.updatedAt).toLocaleDateString()}
                  </td>
                  <td className="w-120px">
                    {res.success ? (
                      <span style={{ color: "green" }}>Success</span>
                    ) : (
                      <span style={{ color: "red" }}>Failed</span>
                    )}
                  </td>
                  <td>{res.message ? res.message : "-"}</td>
                  <td className="w-120px">
                    {res.logininfo?.isMobile ? "App" : "Web"}
                  </td>
                  <td>
                    {res.logininfo
                      ? `${res.logininfo.ip}, ${res.logininfo.device}, ${res.logininfo.country}, ${res.logininfo.timezone}, ${res.logininfo.region} ${res.logininfo.city}`
                      : "-"}
                  </td>
                </tr>
              ))}
          </table>
          <div className="pagination">
            <Pagination
              count={count ? Math.ceil(count / 20) : 0}
              page={page}
              onChange={(e, v) => setPage(v)}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
