export const IndustryKeyMap = [
  {
    key: "0",
    value: 1,
  },
  {
    key: "",
    value: 0,
  },
  {
    key: "Local Shopping",
    value: 2,
  },
  {
    key: "Handmade",
    value: 3,
  },
  {
    key: "Communities",
    value: 4,
  },
  {
    key: "Desktop Apps",
    value: 5,
  },
  {
    key: "Pollution Control",
    value: 6,
  },
  {
    key: "First Aid",
    value: 7,
  },
  {
    key: "Sales",
    value: 8,
  },
  {
    key: "Educational technology",
    value: 9,
  },
  {
    key: "Personal Development",
    value: 10,
  },
  {
    key: "Industrial Manufacturing",
    value: 11,
  },
  {
    key: "Staffing",
    value: 12,
  },
  {
    key: "Water Purification",
    value: 13,
  },
  {
    key: "E-Signature",
    value: 14,
  },
  {
    key: "Medical Device",
    value: 15,
  },
  {
    key: "Children",
    value: 16,
  },
  {
    key: "Software development",
    value: 17,
  },
  {
    key: "Interior Design",
    value: 18,
  },
  {
    key: "Micro Lending",
    value: 19,
  },
  {
    key: "Autonomous vehicle software",
    value: 20,
  },
  {
    key: "Nutraceutical",
    value: 21,
  },
  {
    key: "Autonomous Vehicles",
    value: 22,
  },
  {
    key: "Racing",
    value: 23,
  },
  {
    key: "Electric truck battery manufacturing",
    value: 24,
  },
  {
    key: "Alumni",
    value: 25,
  },
  {
    key: "Janitorial Service",
    value: 26,
  },
  {
    key: "Electric golf cart battery",
    value: 27,
  },
  {
    key: "Health Diagnostics",
    value: 28,
  },
  {
    key: "Financial technology",
    value: 29,
  },
  {
    key: "Subscription Service",
    value: 30,
  },
  {
    key: "Foundries",
    value: 31,
  },
  {
    key: "Internet of Things",
    value: 32,
  },
  {
    key: "Fitness",
    value: 33,
  },
  {
    key: "National Security",
    value: 34,
  },
  {
    key: "Aquaculture",
    value: 35,
  },
  {
    key: "Semiconductor",
    value: 36,
  },
  {
    key: "Diagnostic tools",
    value: 37,
  },
  {
    key: "Music Education",
    value: 38,
  },
  {
    key: "Light Detection and Ranging",
    value: 39,
  },
  {
    key: "Cloud Management",
    value: 40,
  },
  {
    key: "Electric vehicle industry",
    value: 41,
  },
  {
    key: "Commercial Real Estate",
    value: 42,
  },
  {
    key: "Physical Security",
    value: 43,
  },
  {
    key: "EV-wireless charging stations manufacturing (AC/DC)",
    value: 44,
  },
  {
    key: "Online marketplace",
    value: 45,
  },
  {
    key: "Made to Order",
    value: 46,
  },
  {
    key: "Smart City",
    value: 47,
  },
  {
    key: "Investment",
    value: 48,
  },
  {
    key: "Lead Management",
    value: 49,
  },
  {
    key: "Ad Network",
    value: 50,
  },
  {
    key: "Reading Apps",
    value: 51,
  },
  {
    key: "Agricultural technology (AgTech)",
    value: 52,
  },
  {
    key: "Reputation",
    value: 53,
  },
  {
    key: "Ethereum",
    value: 54,
  },
  {
    key: "Space launch",
    value: 55,
  },
  {
    key: "Electric vehicle charging",
    value: 56,
  },
  {
    key: "Nursing and Residential Care",
    value: 57,
  },
  {
    key: "Electric vehicle DC/DC inverter",
    value: 58,
  },
  {
    key: "Browser Extensions",
    value: 59,
  },
  {
    key: "Command, Control, Communication, Computers, Cyber, Intelligence, Surveillance, and Reconnaisance (C5ISR)",
    value: 60,
  },
  {
    key: "Child Care",
    value: 61,
  },
  {
    key: "Home & Garden",
    value: 62,
  },
  {
    key: "User experience design",
    value: 63,
  },
  {
    key: "Aerospace",
    value: 64,
  },
  {
    key: "Virtual Workforce",
    value: 65,
  },
  {
    key: "Social Media",
    value: 66,
  },
  {
    key: "Consulting",
    value: 67,
  },
  {
    key: "Architecture",
    value: 68,
  },
  {
    key: "Wholesale",
    value: 69,
  },
  {
    key: "Trade show display",
    value: 70,
  },
  {
    key: "Beauty",
    value: 71,
  },
  {
    key: "Natural Resources",
    value: 72,
  },
  {
    key: "Supply Chain Management",
    value: 73,
  },
  {
    key: "Innovation Management",
    value: 74,
  },
  {
    key: "Field-Programmable Gate Array (FPGA)",
    value: 75,
  },
  {
    key: "Lingerie",
    value: 76,
  },
  {
    key: "Marketplace",
    value: 77,
  },
  {
    key: "Geospatial",
    value: 78,
  },
  {
    key: "Commercial Lending",
    value: 79,
  },
  {
    key: "Blockchain and cryptocurrency",
    value: 80,
  },
  {
    key: "Homeless Shelter",
    value: 81,
  },
  {
    key: "Sustainable energy",
    value: 82,
  },
  {
    key: "Survey (human research)",
    value: 83,
  },
  {
    key: "Alternative fuel",
    value: 84,
  },
  {
    key: "Delivery",
    value: 85,
  },
  {
    key: "MMO Games",
    value: 86,
  },
  {
    key: "Radio spectrum",
    value: 87,
  },
  {
    key: "Jewelry",
    value: 88,
  },
  {
    key: "Computer-generated imagery",
    value: 89,
  },
  {
    key: "Maritime transport",
    value: 90,
  },
  {
    key: "Creative Agency",
    value: 91,
  },
  {
    key: "Eyewear",
    value: 92,
  },
  {
    key: "Self-Storage",
    value: 93,
  },
  {
    key: "Task Management",
    value: 94,
  },
  {
    key: "Outdoors",
    value: 95,
  },
  {
    key: "Water Transportation",
    value: 96,
  },
  {
    key: "Defense industry",
    value: 97,
  },
  {
    key: "Information Technology",
    value: 98,
  },
  {
    key: "Cricket",
    value: 99,
  },
  {
    key: "Timber",
    value: 100,
  },
  {
    key: "Personal Finance",
    value: 101,
  },
  {
    key: "Content Discovery",
    value: 102,
  },
  {
    key: "Software Engineering",
    value: 103,
  },
  {
    key: "Environmental Consulting",
    value: 104,
  },
  {
    key: "Film Production",
    value: 105,
  },
  {
    key: "Mapping Services",
    value: 106,
  },
  {
    key: "Family",
    value: 107,
  },
  {
    key: "Angel investor",
    value: 108,
  },
  {
    key: "Delivery (commerce)",
    value: 109,
  },
  {
    key: "Vocational Education",
    value: 110,
  },
  {
    key: "Common operational picture",
    value: 111,
  },
  {
    key: "Electric bicycle battery manufacturing",
    value: 112,
  },
  {
    key: "Computer Software",
    value: 113,
  },
  {
    key: "Prediction Markets",
    value: 114,
  },
  {
    key: "Logistics",
    value: 115,
  },
  {
    key: "Unmanned aerial vehicle (UAV)",
    value: 116,
  },
  {
    key: "Home Improvement",
    value: 117,
  },
  {
    key: "Solid-state battery",
    value: 118,
  },
  {
    key: "Confectionery",
    value: 119,
  },
  {
    key: "Wine And Spirits",
    value: 120,
  },
  {
    key: "Bitcoin",
    value: 121,
  },
  {
    key: "Hockey",
    value: 122,
  },
  {
    key: "Debit Cards",
    value: 123,
  },
  {
    key: "Electric vehicle fuel cell",
    value: 124,
  },
  {
    key: "Politics",
    value: 125,
  },
  {
    key: "Digital Entertainment",
    value: 126,
  },
  {
    key: "Golf",
    value: 127,
  },
  {
    key: "Command, Control, Communications, Computers, Intelligence, Surveillance and Reconnaissance (C4ISR)",
    value: 128,
  },
  {
    key: "GovTech",
    value: 129,
  },
  {
    key: "Blogging Platforms",
    value: 130,
  },
  {
    key: "Craft Beer",
    value: 131,
  },
  {
    key: "Collectibles",
    value: 132,
  },
  {
    key: "Electrical Distribution",
    value: 133,
  },
  {
    key: "Semantic Search",
    value: 134,
  },
  {
    key: "Parachains and Parathreads",
    value: 135,
  },
  {
    key: "Amusement Park and Arcade",
    value: 136,
  },
  {
    key: "Connected Devices",
    value: 137,
  },
  {
    key: "Electric car insurance",
    value: 138,
  },
  {
    key: "Electric vehicle drivetrain",
    value: 139,
  },
  {
    key: "Electric vehicle mobility",
    value: 140,
  },
  {
    key: "Cell-based meat",
    value: 141,
  },
  {
    key: "Social Assistance",
    value: 142,
  },
  {
    key: "MOOC",
    value: 143,
  },
  {
    key: "Industrial Design",
    value: 144,
  },
  {
    key: "Photo Editing",
    value: 145,
  },
  {
    key: "Retail",
    value: 146,
  },
  {
    key: "Consulting firm",
    value: 147,
  },
  {
    key: "Insulation",
    value: 148,
  },
  {
    key: "Property Insurance",
    value: 149,
  },
  {
    key: "Meeting Software",
    value: 150,
  },
  {
    key: "Isuzu Elf",
    value: 151,
  },
  {
    key: "Digital Marketing",
    value: 152,
  },
  {
    key: "Vending and Concessions",
    value: 153,
  },
  {
    key: "Powertrain",
    value: 154,
  },
  {
    key: "Vehicle for hire",
    value: 155,
  },
  {
    key: "EdTech",
    value: 156,
  },
  {
    key: "Productivity Tools",
    value: 157,
  },
  {
    key: "Biomass Energy",
    value: 158,
  },
  {
    key: "Car dealership",
    value: 159,
  },
  {
    key: "Collaborative Consumption",
    value: 160,
  },
  {
    key: "Video Editing",
    value: 161,
  },
  {
    key: "Generation Z",
    value: 162,
  },
  {
    key: "Ultimate Frisbee",
    value: 163,
  },
  {
    key: "Adventure Travel",
    value: 164,
  },
  {
    key: "Mobile commerce",
    value: 165,
  },
  {
    key: "Dairy farming",
    value: 166,
  },
  {
    key: "Online Auctions",
    value: 167,
  },
  {
    key: "Data Visualization",
    value: 168,
  },
  {
    key: "GPU",
    value: 169,
  },
  {
    key: "Quantum sensor",
    value: 170,
  },
  {
    key: "Roku",
    value: 171,
  },
  {
    key: "Elder Care",
    value: 172,
  },
  {
    key: "Retail investing",
    value: 173,
  },
  {
    key: "Chemical Engineering",
    value: 174,
  },
  {
    key: "Property Management",
    value: 175,
  },
  {
    key: "Sharing Economy",
    value: 176,
  },
  {
    key: "Electricity",
    value: 177,
  },
  {
    key: "Management Consulting",
    value: 178,
  },
  {
    key: "Animation",
    value: 179,
  },
  {
    key: "Audiobooks",
    value: 180,
  },
  {
    key: "Fantasy Sports",
    value: 181,
  },
  {
    key: "Transportation",
    value: 182,
  },
  {
    key: "Energy supply",
    value: 183,
  },
  {
    key: "Cultured meat",
    value: 184,
  },
  {
    key: "Content Syndication",
    value: 185,
  },
  {
    key: "Archiving Service",
    value: 186,
  },
  {
    key: "Shipping Broker",
    value: 187,
  },
  {
    key: "Metalworking",
    value: 188,
  },
  {
    key: "Test and Measurement",
    value: 189,
  },
  {
    key: "Electric vehicle connector",
    value: 190,
  },
  {
    key: "Social Entrepreneurship",
    value: 191,
  },
  {
    key: "Building Maintenance",
    value: 192,
  },
  {
    key: "Seafood",
    value: 193,
  },
  {
    key: "Information Systems & Services",
    value: 194,
  },
  {
    key: "Missile defense",
    value: 195,
  },
  {
    key: "Music",
    value: 196,
  },
  {
    key: "Virtualization",
    value: 197,
  },
  {
    key: "Auctions",
    value: 198,
  },
  {
    key: "Serology",
    value: 199,
  },
  {
    key: "Limousine Service",
    value: 200,
  },
  {
    key: "Rugby",
    value: 201,
  },
  {
    key: "CMS",
    value: 202,
  },
  {
    key: "Artificial Intelligence",
    value: 203,
  },
  {
    key: "Startup",
    value: 204,
  },
  {
    key: "Non-line-of-sight propagation",
    value: 205,
  },
  {
    key: "Facility Management",
    value: 206,
  },
  {
    key: "Delivery Service",
    value: 207,
  },
  {
    key: "Dietary Supplements",
    value: 208,
  },
  {
    key: "Twitter",
    value: 209,
  },
  {
    key: "Lead Generation",
    value: 210,
  },
  {
    key: "Health Care",
    value: 211,
  },
  {
    key: "Contact Management",
    value: 212,
  },
  {
    key: "Homeland Security",
    value: 213,
  },
  {
    key: "Small and Medium Businesses",
    value: 214,
  },
  {
    key: "Personal Branding",
    value: 215,
  },
  {
    key: "Data Storage",
    value: 216,
  },
  {
    key: "Radio-frequency identification (RFID)",
    value: 217,
  },
  {
    key: "Traceability",
    value: 218,
  },
  {
    key: "InsurTech",
    value: 219,
  },
  {
    key: "Intrusion Detection",
    value: 220,
  },
  {
    key: "Financial Exchanges",
    value: 221,
  },
  {
    key: "Management Information Systems",
    value: 222,
  },
  {
    key: "B2C",
    value: 223,
  },
  {
    key: "Low-Speed Autonomy",
    value: 224,
  },
  {
    key: "Real Time",
    value: 225,
  },
  {
    key: "Freighting",
    value: 226,
  },
  {
    key: "Artificial Intelligence (AI",
    value: 227,
  },
  {
    key: "Parking",
    value: 228,
  },
  {
    key: "Magnetometer Drone",
    value: 229,
  },
  {
    key: "Audio",
    value: 230,
  },
  {
    key: "Insurance",
    value: 231,
  },
  {
    key: "Language Learning",
    value: 232,
  },
  {
    key: "Millimeter-wave",
    value: 233,
  },
  {
    key: "Video Chat",
    value: 234,
  },
  {
    key: "Teenagers",
    value: 235,
  },
  {
    key: "Generation Y",
    value: 236,
  },
  {
    key: "Advertising Platforms",
    value: 237,
  },
  {
    key: "Recreational Vehicles",
    value: 238,
  },
  {
    key: "Concerts",
    value: 239,
  },
  {
    key: "Camera",
    value: 240,
  },
  {
    key: "Developer Tools",
    value: 241,
  },
  {
    key: "A/B Testing",
    value: 242,
  },
  {
    key: "Health Systems",
    value: 243,
  },
  {
    key: "Magnetometer",
    value: 244,
  },
  {
    key: "Trade Shows",
    value: 245,
  },
  {
    key: "Data Mining",
    value: 246,
  },
  {
    key: "Tactical communications",
    value: 247,
  },
  {
    key: "Electric bus battery manufacturing",
    value: 248,
  },
  {
    key: "Freelance",
    value: 249,
  },
  {
    key: "Alternative proteins",
    value: 250,
  },
  {
    key: "Banking industry",
    value: 251,
  },
  {
    key: "Chemical industry",
    value: 252,
  },
  {
    key: "Truck stop",
    value: 253,
  },
  {
    key: "Plug-in electric vehicle",
    value: 254,
  },
  {
    key: "Systems engineering",
    value: 255,
  },
  {
    key: "Pharmaceutical",
    value: 256,
  },
  {
    key: "Lending",
    value: 257,
  },
  {
    key: "Social Media Advertising",
    value: 258,
  },
  {
    key: "Venture Capital",
    value: 259,
  },
  {
    key: "Battery",
    value: 260,
  },
  {
    key: "Association",
    value: 261,
  },
  {
    key: "Email Marketing",
    value: 262,
  },
  {
    key: "Trading Platform",
    value: 263,
  },
  {
    key: "Medical",
    value: 264,
  },
  {
    key: "Social Impact",
    value: 265,
  },
  {
    key: "STEM Education",
    value: 266,
  },
  {
    key: "Automobile insurance",
    value: 267,
  },
  {
    key: "Virtual Desktop",
    value: 268,
  },
  {
    key: "Playstation",
    value: 269,
  },
  {
    key: "PaaS",
    value: 270,
  },
  {
    key: "Electric vehicle charging location search",
    value: 271,
  },
  {
    key: "Oil & Energy",
    value: 272,
  },
  {
    key: "Infrastructure",
    value: 273,
  },
  {
    key: "Customer experience",
    value: 274,
  },
  {
    key: "Farming",
    value: 275,
  },
  {
    key: "Collaboration",
    value: 276,
  },
  {
    key: "Gift",
    value: 277,
  },
  {
    key: "Cryptocurrency",
    value: 278,
  },
  {
    key: "Nuclear",
    value: 279,
  },
  {
    key: "Mineral",
    value: 280,
  },
  {
    key: "Hotel",
    value: 281,
  },
  {
    key: "Robot",
    value: 282,
  },
  {
    key: "EV-fast charging stations manufacturing",
    value: 283,
  },
  {
    key: "PC Games",
    value: 284,
  },
  {
    key: "Tourism",
    value: 285,
  },
  {
    key: "Recycling",
    value: 286,
  },
  {
    key: "SMS",
    value: 287,
  },
  {
    key: "Car Sharing",
    value: 288,
  },
  {
    key: "Same Day Delivery",
    value: 289,
  },
  {
    key: "Satellite",
    value: 290,
  },
  {
    key: "Business-to-business",
    value: 291,
  },
  {
    key: "Social Media Marketing",
    value: 292,
  },
  {
    key: "Solar electric airplane manufacturing",
    value: 293,
  },
  {
    key: "Leisure",
    value: 294,
  },
  {
    key: "Railroad",
    value: 295,
  },
  {
    key: "Web Browsers",
    value: 296,
  },
  {
    key: "Consumer Electronics",
    value: 297,
  },
  {
    key: "Google Glass",
    value: 298,
  },
  {
    key: "Domain Registrar",
    value: 299,
  },
  {
    key: "Product Design",
    value: 300,
  },
  {
    key: "Veterinary",
    value: 301,
  },
  {
    key: "IT Infrastructure",
    value: 302,
  },
  {
    key: "Body armor",
    value: 303,
  },
  {
    key: "In-Flight Entertainment",
    value: 304,
  },
  {
    key: "Residential",
    value: 305,
  },
  {
    key: "Email",
    value: 306,
  },
  {
    key: "Veterinary Services",
    value: 307,
  },
  {
    key: "3PL",
    value: 308,
  },
  {
    key: "Security",
    value: 309,
  },
  {
    key: "Cybersecurity",
    value: 310,
  },
  {
    key: "Winery",
    value: 311,
  },
  {
    key: "Electronic Design Automation (EDA)",
    value: 312,
  },
  {
    key: "Biotechnology",
    value: 313,
  },
  {
    key: "Tobacco",
    value: 314,
  },
  {
    key: "Performing Arts",
    value: 315,
  },
  {
    key: "Product Management",
    value: 316,
  },
  {
    key: "Smart Cities",
    value: 317,
  },
  {
    key: "Precious Metals",
    value: 318,
  },
  {
    key: "Serious Games",
    value: 319,
  },
  {
    key: "Energy",
    value: 320,
  },
  {
    key: "Electric power distribution",
    value: 321,
  },
  {
    key: "Freemium",
    value: 322,
  },
  {
    key: "Accounting",
    value: 323,
  },
  {
    key: "Communications system",
    value: 324,
  },
  {
    key: "Mobile Advertising",
    value: 325,
  },
  {
    key: "Emergency Medicine",
    value: 326,
  },
  {
    key: "Legal Tech",
    value: 327,
  },
  {
    key: "Health information technology",
    value: 328,
  },
  {
    key: "Loan",
    value: 329,
  },
  {
    key: "Social CRM",
    value: 330,
  },
  {
    key: "Usability Testing",
    value: 331,
  },
  {
    key: "Swimming",
    value: 332,
  },
  {
    key: "E-Commerce",
    value: 333,
  },
  {
    key: "Content Delivery Network",
    value: 334,
  },
  {
    key: "Film",
    value: 335,
  },
  {
    key: "DRM",
    value: 336,
  },
  {
    key: "Field Support",
    value: 337,
  },
  {
    key: "Service provider",
    value: 338,
  },
  {
    key: "B2B",
    value: 339,
  },
  {
    key: "Information and Communications Technology (ICT)",
    value: 340,
  },
  {
    key: "Fertility",
    value: 341,
  },
  {
    key: "Internet Radio",
    value: 342,
  },
  {
    key: "Engineering",
    value: 343,
  },
  {
    key: "Education",
    value: 344,
  },
  {
    key: "DSP",
    value: 345,
  },
  {
    key: "Life Science",
    value: 346,
  },
  {
    key: "EV-battery manufacturing",
    value: 347,
  },
  {
    key: "Synthetic-aperture radar",
    value: 348,
  },
  {
    key: "Livestock",
    value: 349,
  },
  {
    key: "Food Tech",
    value: 350,
  },
  {
    key: "Mobile Apps",
    value: 351,
  },
  {
    key: "Retail transport",
    value: 352,
  },
  {
    key: "Musical Instruments",
    value: 353,
  },
  {
    key: "Lifestyle",
    value: 354,
  },
  {
    key: "Franchise",
    value: 355,
  },
  {
    key: "Manufacturing",
    value: 356,
  },
  {
    key: "Simulation",
    value: 357,
  },
  {
    key: "Open Source",
    value: 358,
  },
  {
    key: "Wood Processing",
    value: 359,
  },
  {
    key: "Recipes",
    value: 360,
  },
  {
    key: "Enterprise Applications",
    value: 361,
  },
  {
    key: "IT Management",
    value: 362,
  },
  {
    key: "UX Design",
    value: 363,
  },
  {
    key: "Online Forums",
    value: 364,
  },
  {
    key: "Packaging Services",
    value: 365,
  },
  {
    key: "Funding Platform",
    value: 366,
  },
  {
    key: "Software as a service (SaaS)",
    value: 367,
  },
  {
    key: "Clean technology",
    value: 368,
  },
  {
    key: "Coworking",
    value: 369,
  },
  {
    key: "Offshore construction",
    value: 370,
  },
  {
    key: "Transportation engineering",
    value: 371,
  },
  {
    key: "Social commerce",
    value: 372,
  },
  {
    key: "Boating",
    value: 373,
  },
  {
    key: "Media and Entertainment",
    value: 374,
  },
  {
    key: "Network Hardware",
    value: 375,
  },
  {
    key: "E-Commerce Platforms",
    value: 376,
  },
  {
    key: "Tennis",
    value: 377,
  },
  {
    key: "Film Distribution",
    value: 378,
  },
  {
    key: "Food and Beverage",
    value: 379,
  },
  {
    key: "Electric van conversion",
    value: 380,
  },
  {
    key: "Data Center",
    value: 381,
  },
  {
    key: "Social Bookmarking",
    value: 382,
  },
  {
    key: "COVID-19 testing",
    value: 383,
  },
  {
    key: "Live, virtual, and constructive training",
    value: 384,
  },
  {
    key: "Digital Media",
    value: 385,
  },
  {
    key: "Vertical Search",
    value: 386,
  },
  {
    key: "Motion Capture",
    value: 387,
  },
  {
    key: "Stock Exchanges",
    value: 388,
  },
  {
    key: "Big Data",
    value: 389,
  },
  {
    key: "Internet",
    value: 390,
  },
  {
    key: "Leasing",
    value: 391,
  },
  {
    key: "Power Grid",
    value: 392,
  },
  {
    key: "Direct Marketing",
    value: 393,
  },
  {
    key: "Geographic information system",
    value: 394,
  },
  {
    key: "Recreation",
    value: 395,
  },
  {
    key: "Electronics",
    value: 396,
  },
  {
    key: "Golf cart",
    value: 397,
  },
  {
    key: "Communication Hardware",
    value: 398,
  },
  {
    key: "Veterinary medicine",
    value: 399,
  },
  {
    key: "Enterprise Resource Planning (ERP)",
    value: 400,
  },
  {
    key: "American Football",
    value: 401,
  },
  {
    key: "Clinical Trials",
    value: 402,
  },
  {
    key: "Privacy",
    value: 403,
  },
  {
    key: "Direct Sales",
    value: 404,
  },
  {
    key: "Cycling",
    value: 405,
  },
  {
    key: "Network Security",
    value: 406,
  },
  {
    key: "Electric airplane manufacturing",
    value: 407,
  },
  {
    key: "Alternative Medicine",
    value: 408,
  },
  {
    key: "College Recruiting",
    value: 409,
  },
  {
    key: "Marketing",
    value: 410,
  },
  {
    key: "Green Building",
    value: 411,
  },
  {
    key: "Non Profit",
    value: 412,
  },
  {
    key: "Gas",
    value: 413,
  },
  {
    key: "Public Safety",
    value: 414,
  },
  {
    key: "Technical Support",
    value: 415,
  },
  {
    key: "Assistive Technology",
    value: 416,
  },
  {
    key: "Religion",
    value: 417,
  },
  {
    key: "Wireless",
    value: 418,
  },
  {
    key: "Social Media Management",
    value: 419,
  },
  {
    key: "Oceanography",
    value: 420,
  },
  {
    key: "Advice",
    value: 421,
  },
  {
    key: "Facebook",
    value: 422,
  },
  {
    key: "Robotics",
    value: 423,
  },
  {
    key: "Electric truck manufacturing",
    value: 424,
  },
  {
    key: "Geospatial intelligence",
    value: 425,
  },
  {
    key: "Cross laminated timber",
    value: 426,
  },
  {
    key: "Video",
    value: 427,
  },
  {
    key: "Office Administration",
    value: 428,
  },
  {
    key: "Comics",
    value: 429,
  },
  {
    key: "Electric car leasing",
    value: 430,
  },
  {
    key: "Charity",
    value: 431,
  },
  {
    key: "Simultaneous Localization and Mapping",
    value: 432,
  },
  {
    key: "Ferry Service",
    value: 433,
  },
  {
    key: "Cooking",
    value: 434,
  },
  {
    key: "Nanotechnology",
    value: 435,
  },
  {
    key: "Electric vehicle motor",
    value: 436,
  },
  {
    key: "Embedded Systems",
    value: 437,
  },
  {
    key: "Intelligence, Surveillance and Reconnaissance",
    value: 438,
  },
  {
    key: "Sensor",
    value: 439,
  },
  {
    key: "Psychology",
    value: 440,
  },
  {
    key: "Emerging Markets",
    value: 441,
  },
  {
    key: "Contests",
    value: 442,
  },
  {
    key: "Outpatient Care",
    value: 443,
  },
  {
    key: "Gift Registry",
    value: 444,
  },
  {
    key: "Automation",
    value: 445,
  },
  {
    key: "Car rental",
    value: 446,
  },
  {
    key: "Electronic ticket",
    value: 447,
  },
  {
    key: "Mechanical Engineering",
    value: 448,
  },
  {
    key: "Content Marketing",
    value: 449,
  },
  {
    key: "File Sharing",
    value: 450,
  },
  {
    key: "Ad Targeting",
    value: 451,
  },
  {
    key: "Combustion to electric Hydrography vehicle conversion",
    value: 452,
  },
  {
    key: "Gadget",
    value: 453,
  },
  {
    key: "Theatre",
    value: 454,
  },
  {
    key: "Plastics and Rubber Manufacturing",
    value: 455,
  },
  {
    key: "Mobile",
    value: 456,
  },
  {
    key: "Procurement",
    value: 457,
  },
  {
    key: "Tour Operator",
    value: 458,
  },
  {
    key: "Mobile technology",
    value: 459,
  },
  {
    key: "Higher Education",
    value: 460,
  },
  {
    key: "Sustainable commerce",
    value: 461,
  },
  {
    key: "Penetration Testing",
    value: 462,
  },
  {
    key: "Pharmaceutical industry",
    value: 463,
  },
  {
    key: "Counter-unmanned aerial vehicle (UAV) system",
    value: 464,
  },
  {
    key: "Retirement",
    value: 465,
  },
  {
    key: "Metro",
    value: 466,
  },
  {
    key: "Plastic Industry",
    value: 467,
  },
  {
    key: "Angel Investment",
    value: 468,
  },
  {
    key: "Property Development",
    value: 469,
  },
  {
    key: "Energy Management",
    value: 470,
  },
  {
    key: "Military",
    value: 471,
  },
  {
    key: "Art",
    value: 472,
  },
  {
    key: "Environmental science",
    value: 473,
  },
  {
    key: "Virtual Goods",
    value: 474,
  },
  {
    key: "macOS",
    value: 475,
  },
  {
    key: "Neurotechnology",
    value: 476,
  },
  {
    key: "Gambling",
    value: 477,
  },
  {
    key: "Ad Exchange",
    value: 478,
  },
  {
    key: "Enterprise Software",
    value: 479,
  },
  {
    key: "Apps",
    value: 480,
  },
  {
    key: "Precision agriculture",
    value: 481,
  },
  {
    key: "Auto Insurance",
    value: 482,
  },
  {
    key: "FinTech",
    value: 483,
  },
  {
    key: "Animal Feed",
    value: 484,
  },
  {
    key: "Electric vehicle financial service",
    value: 485,
  },
  {
    key: "Occupational safety and health",
    value: 486,
  },
  {
    key: "Electric motorcycle manufacturing",
    value: 487,
  },
  {
    key: "Driving",
    value: 488,
  },
  {
    key: "Rehabilitation",
    value: 489,
  },
  {
    key: "Local Advertising",
    value: 490,
  },
  {
    key: "Brewing",
    value: 491,
  },
  {
    key: "Postal Service",
    value: 492,
  },
  {
    key: "Spam Filtering",
    value: 493,
  },
  {
    key: "Skiing",
    value: 494,
  },
  {
    key: "Agricultural machinery industry",
    value: 495,
  },
  {
    key: "LGBT",
    value: 496,
  },
  {
    key: "Analytics",
    value: 497,
  },
  {
    key: "Hospital",
    value: 498,
  },
  {
    key: "Electric car rental",
    value: 499,
  },
  {
    key: "Sex Tech",
    value: 500,
  },
  {
    key: "Child Care Services",
    value: 501,
  },
  {
    key: "Automobile repair shop",
    value: 502,
  },
  {
    key: "Green Consumer Goods",
    value: 503,
  },
  {
    key: "Secondary Education",
    value: 504,
  },
  {
    key: "Solar",
    value: 505,
  },
  {
    key: "Home and Garden",
    value: 506,
  },
  {
    key: "Mobile Payments",
    value: 507,
  },
  {
    key: "Energy Storage",
    value: 508,
  },
  {
    key: "NFC",
    value: 509,
  },
  {
    key: "Baby",
    value: 510,
  },
  {
    key: "Solution Analysts",
    value: 511,
  },
  {
    key: "Travel",
    value: 512,
  },
  {
    key: "Laundry and Dry-cleaning",
    value: 513,
  },
  {
    key: "Financial Services",
    value: 514,
  },
  {
    key: "Space logistics",
    value: 515,
  },
  {
    key: "Grocery",
    value: 516,
  },
  {
    key: "Landscaping",
    value: 517,
  },
  {
    key: "News media",
    value: 518,
  },
  {
    key: "Surgical mask ear-saver band",
    value: 519,
  },
  {
    key: "Charging station",
    value: 520,
  },
  {
    key: "Durability",
    value: 521,
  },
  {
    key: "Forestry",
    value: 522,
  },
  {
    key: "Air traffic service",
    value: 523,
  },
  {
    key: "Developer APIs",
    value: 524,
  },
  {
    key: "Flowers",
    value: 525,
  },
  {
    key: "Affiliate Marketing",
    value: 526,
  },
  {
    key: "Quantum Computing",
    value: 527,
  },
  {
    key: "Medical diagnostics",
    value: 528,
  },
  {
    key: "Digital Signage",
    value: 529,
  },
  {
    key: "Assisted Living",
    value: 530,
  },
  {
    key: "SEO",
    value: 531,
  },
  {
    key: "Sailing",
    value: 532,
  },
  {
    key: "Electric vehicle cables",
    value: 533,
  },
  {
    key: "Visual Search",
    value: 534,
  },
  {
    key: "Map",
    value: 535,
  },
  {
    key: "Presentations",
    value: 536,
  },
  {
    key: "Nintendo",
    value: 537,
  },
  {
    key: "Fast-Moving Consumer Goods",
    value: 538,
  },
  {
    key: "Ride Sharing",
    value: 539,
  },
  {
    key: "Hospitality Industry",
    value: 540,
  },
  {
    key: "Banking",
    value: 541,
  },
  {
    key: "Clean Energy",
    value: 542,
  },
  {
    key: "Knowledge Management",
    value: 543,
  },
  {
    key: "Private Social Networking",
    value: 544,
  },
  {
    key: "Smart Home",
    value: 545,
  },
  {
    key: "Rental",
    value: 546,
  },
  {
    key: "Basketball",
    value: 547,
  },
  {
    key: "Catering",
    value: 548,
  },
  {
    key: "Funerals",
    value: 549,
  },
  {
    key: "On-demand",
    value: 550,
  },
  {
    key: "Food industry",
    value: 551,
  },
  {
    key: "Developer Platform",
    value: 552,
  },
  {
    key: "Electric bus manufacturing",
    value: 553,
  },
  {
    key: "Electric utility vehicle manufacturing",
    value: 554,
  },
  {
    key: "Elderly",
    value: 555,
  },
  {
    key: "Port and Harbor Operations",
    value: 556,
  },
  {
    key: "Therapeutics",
    value: 557,
  },
  {
    key: "Business analytics",
    value: 558,
  },
  {
    key: "Online to offline",
    value: 559,
  },
  {
    key: "Casual Games",
    value: 560,
  },
  {
    key: "Real Estate",
    value: 561,
  },
  {
    key: "Service Industry",
    value: 562,
  },
  {
    key: "Management of Companies and Enterprises",
    value: 563,
  },
  {
    key: "Men's",
    value: 564,
  },
  {
    key: "Quality Assurance",
    value: 565,
  },
  {
    key: "Messaging",
    value: 566,
  },
  {
    key: "Global Positioning System",
    value: 567,
  },
  {
    key: "Fleet Management",
    value: 568,
  },
  {
    key: "Laser",
    value: 569,
  },
  {
    key: "Flash Sale",
    value: 570,
  },
  {
    key: "Aerospace manufacturer",
    value: 571,
  },
  {
    key: "ISP",
    value: 572,
  },
  {
    key: "Intelligent Systems",
    value: 573,
  },
  {
    key: "Swarm robotics",
    value: 574,
  },
  {
    key: "Space industry",
    value: 575,
  },
  {
    key: "mHealth",
    value: 576,
  },
  {
    key: "Operating Systems",
    value: 577,
  },
  {
    key: "Small and medium-sized enterprises",
    value: 578,
  },
  {
    key: "Online Shopping",
    value: 579,
  },
  {
    key: "Local Business",
    value: 580,
  },
  {
    key: "Nuclear power",
    value: 581,
  },
  {
    key: "Fleet management",
    value: 582,
  },
  {
    key: "eMobility service",
    value: 583,
  },
  {
    key: "Ports and Harbors",
    value: 584,
  },
  {
    key: "Drones",
    value: 585,
  },
  {
    key: "Shopping Mall",
    value: 586,
  },
  {
    key: "Employee Benefits",
    value: 587,
  },
  {
    key: "Electric car manufacturing",
    value: 588,
  },
  {
    key: "RISC",
    value: 589,
  },
  {
    key: "Advanced Materials",
    value: 590,
  },
  {
    key: "Mobile Devices",
    value: 591,
  },
  {
    key: "Asset Management",
    value: 592,
  },
  {
    key: "Business Development",
    value: 593,
  },
  {
    key: "Content",
    value: 594,
  },
  {
    key: "Application software",
    value: 595,
  },
  {
    key: "Business intelligence (BI)",
    value: 596,
  },
  {
    key: "Information technology consulting",
    value: 597,
  },
  {
    key: "E-Learning",
    value: 598,
  },
  {
    key: "Food Trucks",
    value: 599,
  },
  {
    key: "Armor",
    value: 600,
  },
  {
    key: "Darknet",
    value: 601,
  },
  {
    key: "Machine Learning",
    value: 602,
  },
  {
    key: "Chemical",
    value: 603,
  },
  {
    key: "EV-charging manufacturing",
    value: 604,
  },
  {
    key: "Diving",
    value: 605,
  },
  {
    key: "Furniture",
    value: 606,
  },
  {
    key: "Gamification",
    value: 607,
  },
  {
    key: "Cosmetic Surgery",
    value: 608,
  },
  {
    key: "Industrial",
    value: 609,
  },
  {
    key: "Digital currency",
    value: 610,
  },
  {
    key: "iOS",
    value: 611,
  },
  {
    key: "Agriculture",
    value: 612,
  },
  {
    key: "Professional Networking",
    value: 613,
  },
  {
    key: "Productivity software",
    value: 614,
  },
  {
    key: "Mining Technology",
    value: 615,
  },
  {
    key: "App Discovery",
    value: 616,
  },
  {
    key: "Adult",
    value: 617,
  },
  {
    key: "Organic",
    value: 618,
  },
  {
    key: "Blockchain",
    value: 619,
  },
  {
    key: "QR Codes",
    value: 620,
  },
  {
    key: "Consumer Reviews",
    value: 621,
  },
  {
    key: "GreenTech",
    value: 622,
  },
  {
    key: "Tea",
    value: 623,
  },
  {
    key: "Hardware",
    value: 624,
  },
  {
    key: "Peer-to-peer",
    value: 625,
  },
  {
    key: "Information Services",
    value: 626,
  },
  {
    key: "Social Shopping",
    value: 627,
  },
  {
    key: "Industrial Engineering",
    value: 628,
  },
  {
    key: "Quantified Self",
    value: 629,
  },
  {
    key: "eSports",
    value: 630,
  },
  {
    key: "Hunting",
    value: 631,
  },
  {
    key: "Gift Exchange",
    value: 632,
  },
  {
    key: "IaaS",
    value: 633,
  },
  {
    key: "Defense & Space",
    value: 634,
  },
  {
    key: "Predictive Analytics",
    value: 635,
  },
  {
    key: "Travel Agency",
    value: 636,
  },
  {
    key: "Surfing",
    value: 637,
  },
  {
    key: "Professional Services",
    value: 638,
  },
  {
    key: "5G",
    value: 639,
  },
  {
    key: "News",
    value: 640,
  },
  {
    key: "Certification",
    value: 641,
  },
  {
    key: "Cinematography",
    value: 642,
  },
  {
    key: "Photography",
    value: 643,
  },
  {
    key: "CleanTech",
    value: 644,
  },
  {
    key: "Marine Technology",
    value: 645,
  },
  {
    key: "Billing",
    value: 646,
  },
  {
    key: "Recruiting",
    value: 647,
  },
  {
    key: "Civil Engineering",
    value: 648,
  },
  {
    key: "Coupons",
    value: 649,
  },
  {
    key: "Autonomous drive manufacturing",
    value: 650,
  },
  {
    key: "Government",
    value: 651,
  },
  {
    key: "Safety",
    value: 652,
  },
  {
    key: "Inductive charging",
    value: 653,
  },
  {
    key: "Electric vehicle AC/DC converter",
    value: 654,
  },
  {
    key: "Lighting",
    value: 655,
  },
  {
    key: "Identity Management",
    value: 656,
  },
  {
    key: "Mobility",
    value: 657,
  },
  {
    key: "Biomedical engineering",
    value: 658,
  },
  {
    key: "Translation",
    value: 659,
  },
  {
    key: "Social News",
    value: 660,
  },
  {
    key: "Coffee",
    value: 661,
  },
  {
    key: "Electric scooter manufacturing",
    value: 662,
  },
  {
    key: "Business Travel",
    value: 663,
  },
  {
    key: "Explainable artificial intelligence (XAI)",
    value: 664,
  },
  {
    key: "Medical Devices",
    value: 665,
  },
  {
    key: "Aerial photography",
    value: 666,
  },
  {
    key: "Transportation and Warehousing",
    value: 667,
  },
  {
    key: "EV-charging station manufacturing",
    value: 668,
  },
  {
    key: "Consulting industry",
    value: 669,
  },
  {
    key: "Fire safety",
    value: 670,
  },
  {
    key: "Location-based service",
    value: 671,
  },
  {
    key: "Vacation Rental",
    value: 672,
  },
  {
    key: "Sponsorship",
    value: 673,
  },
  {
    key: "Design",
    value: 674,
  },
  {
    key: "Computer Vision",
    value: 675,
  },
  {
    key: "Entrepreneurship",
    value: 676,
  },
  {
    key: "Multi-level Marketing",
    value: 677,
  },
  {
    key: "Restoration Services",
    value: 678,
  },
  {
    key: "Software industry",
    value: 679,
  },
  {
    key: "Navigation",
    value: 680,
  },
  {
    key: "App Marketing",
    value: 681,
  },
  {
    key: "Outsourcing",
    value: 682,
  },
  {
    key: "Printer (publishing)",
    value: 683,
  },
  {
    key: "Mining",
    value: 684,
  },
  {
    key: "Medicine",
    value: 685,
  },
  {
    key: "Consumer Applications",
    value: 686,
  },
  {
    key: "Arms industry",
    value: 687,
  },
  {
    key: "Water",
    value: 688,
  },
  {
    key: "Optical Communication",
    value: 689,
  },
  {
    key: "Legal",
    value: 690,
  },
  {
    key: "TV Production",
    value: 691,
  },
  {
    key: "Vehicle insurance",
    value: 692,
  },
  {
    key: "Toys",
    value: 693,
  },
  {
    key: "Cosmetics",
    value: 694,
  },
  {
    key: "Museums and Historical Sites",
    value: 695,
  },
  {
    key: "Fashion",
    value: 696,
  },
  {
    key: "Event Promotion",
    value: 697,
  },
  {
    key: "Project Management",
    value: 698,
  },
  {
    key: "Toyota",
    value: 699,
  },
  {
    key: "Publishing",
    value: 700,
  },
  {
    key: "Renewable Energy",
    value: 701,
  },
  {
    key: "Online Games",
    value: 702,
  },
  {
    key: "Sports",
    value: 703,
  },
  {
    key: "Web Apps",
    value: 704,
  },
  {
    key: "Women's",
    value: 705,
  },
  {
    key: "Ediscovery",
    value: 706,
  },
  {
    key: "Drone Management",
    value: 707,
  },
  {
    key: "Social",
    value: 708,
  },
  {
    key: "Document Preparation",
    value: 709,
  },
  {
    key: "Local search (Internet)",
    value: 710,
  },
  {
    key: "Indoor Positioning",
    value: 711,
  },
  {
    key: "Collection Agency",
    value: 712,
  },
  {
    key: "Airline",
    value: 713,
  },
  {
    key: "Electric tricycle manufacturing",
    value: 714,
  },
  {
    key: "Staffing Agency",
    value: 715,
  },
  {
    key: "Public Transportation",
    value: 716,
  },
  {
    key: "Personal Health",
    value: 717,
  },
  {
    key: "Consumer",
    value: 718,
  },
  {
    key: "Compliance",
    value: 719,
  },
  {
    key: "Gaming",
    value: 720,
  },
  {
    key: "Business Intelligence",
    value: 721,
  },
  {
    key: "Aircraft industry",
    value: 722,
  },
  {
    key: "Millennials",
    value: 723,
  },
  {
    key: "Electric car battery manufacturing",
    value: 724,
  },
  {
    key: "Private Cloud",
    value: 725,
  },
  {
    key: "Console Games",
    value: 726,
  },
  {
    key: "Diabetes",
    value: 727,
  },
  {
    key: "Marketing Automation",
    value: 728,
  },
  {
    key: "Price Comparison",
    value: 729,
  },
  {
    key: "Truck",
    value: 730,
  },
  {
    key: "Ticketing",
    value: 731,
  },
  {
    key: "Marine Transportation",
    value: 732,
  },
  {
    key: "Construction",
    value: 733,
  },
  {
    key: "Automotive design",
    value: 734,
  },
  {
    key: "Electric vehicle battery cell",
    value: 735,
  },
  {
    key: "Industrial Automation",
    value: 736,
  },
  {
    key: "Android",
    value: 737,
  },
  {
    key: "Search Engine",
    value: 738,
  },
  {
    key: "RFID",
    value: 739,
  },
  {
    key: "Cyber Security",
    value: 740,
  },
  {
    key: "Lithium-ion battery",
    value: 741,
  },
  {
    key: "Wealth Management",
    value: 742,
  },
  {
    key: "Hydrography",
    value: 743,
  },
  {
    key: "Consumer Research",
    value: 744,
  },
  {
    key: "Mail",
    value: 745,
  },
  {
    key: "CRM",
    value: 746,
  },
  {
    key: "Unified Communications",
    value: 747,
  },
  {
    key: "Humanitarian",
    value: 748,
  },
  {
    key: "Outdoor Advertising",
    value: 749,
  },
  {
    key: "Energy Efficiency",
    value: 750,
  },
  {
    key: "Web hosting service",
    value: 751,
  },
  {
    key: "Hospitality",
    value: 752,
  },
  {
    key: "Sex Industry",
    value: 753,
  },
  {
    key: "Credit Bureau",
    value: 754,
  },
  {
    key: "Wellness",
    value: 755,
  },
  {
    key: "Cleaning",
    value: 756,
  },
  {
    key: "Cause Marketing",
    value: 757,
  },
  {
    key: "SaaS",
    value: 758,
  },
  {
    key: "Video Advertising",
    value: 759,
  },
  {
    key: "Point of Sale",
    value: 760,
  },
  {
    key: "Sporting Goods",
    value: 761,
  },
  {
    key: "Presentation Software",
    value: 762,
  },
  {
    key: "General aviation",
    value: 763,
  },
  {
    key: "Charter Schools",
    value: 764,
  },
  {
    key: "Hydrogen power",
    value: 765,
  },
  {
    key: "Last Mile Transportation",
    value: 766,
  },
  {
    key: "Location Based Services",
    value: 767,
  },
  {
    key: "Computer",
    value: 768,
  },
  {
    key: "SEM",
    value: 769,
  },
  {
    key: "Travel technology",
    value: 770,
  },
  {
    key: "Electric bicycle",
    value: 771,
  },
  {
    key: "Textbook",
    value: 772,
  },
  {
    key: "Electric vehicle plug",
    value: 773,
  },
  {
    key: "Enterprise software",
    value: 774,
  },
  {
    key: "Cloud Infrastructure",
    value: 775,
  },
  {
    key: "Video Games",
    value: 776,
  },
  {
    key: "Man-portable air defense system",
    value: 777,
  },
  {
    key: "EBooks",
    value: 778,
  },
  {
    key: "Industry",
    value: 779,
  },
  {
    key: "Hydrogen fuel",
    value: 780,
  },
  {
    key: "Electric vehicle battery technology",
    value: 781,
  },
  {
    key: "Semantic Web",
    value: 782,
  },
  {
    key: "VoIP",
    value: 783,
  },
  {
    key: "Electric bike kit",
    value: 784,
  },
  {
    key: "Travel Accommodations",
    value: 785,
  },
  {
    key: "Payments",
    value: 786,
  },
  {
    key: "Taxis",
    value: 787,
  },
  {
    key: "Wedding",
    value: 788,
  },
  {
    key: "Service (economics)",
    value: 789,
  },
  {
    key: "Healthcare",
    value: 790,
  },
  {
    key: "Horticulture",
    value: 791,
  },
  {
    key: "Corporate Training",
    value: 792,
  },
  {
    key: "Medical ventilator",
    value: 793,
  },
  {
    key: "Information industry",
    value: 794,
  },
  {
    key: "Quantum technology",
    value: 795,
  },
  {
    key: "Freight Service",
    value: 796,
  },
  {
    key: "Satellite Communication",
    value: 797,
  },
  {
    key: "Electric vehicle battery test equipment",
    value: 798,
  },
  {
    key: "Shopping",
    value: 799,
  },
  {
    key: "Autonomous truck",
    value: 800,
  },
  {
    key: "SNS",
    value: 801,
  },
  {
    key: "Micromobility",
    value: 802,
  },
  {
    key: "Extremely high frequency",
    value: 803,
  },
  {
    key: "Skill Assessment",
    value: 804,
  },
  {
    key: "Cloud Data Services",
    value: 805,
  },
  {
    key: "Autonomous System",
    value: 806,
  },
  {
    key: "Mechanical Design",
    value: 807,
  },
  {
    key: "Universities",
    value: 808,
  },
  {
    key: "Classifieds",
    value: 809,
  },
  {
    key: "Electric scooter battery manufacturing",
    value: 810,
  },
  {
    key: "Biopharma",
    value: 811,
  },
  {
    key: "Honda",
    value: 812,
  },
  {
    key: "Nutrition",
    value: 813,
  },
  {
    key: "Farmers Market",
    value: 814,
  },
  {
    key: "Building Material",
    value: 815,
  },
  {
    key: "Home Renovation",
    value: 816,
  },
  {
    key: "Facial Recognition",
    value: 817,
  },
  {
    key: "Text Analytics",
    value: 818,
  },
  {
    key: "Risk Management",
    value: 819,
  },
  {
    key: "Electric power industry",
    value: 820,
  },
  {
    key: "Underserved Children",
    value: 821,
  },
  {
    key: "Commercial Insurance",
    value: 822,
  },
  {
    key: "Shoes",
    value: 823,
  },
  {
    key: "Sales Automation",
    value: 824,
  },
  {
    key: "Impact Investing",
    value: 825,
  },
  {
    key: "Q&A",
    value: 826,
  },
  {
    key: "Data Center Automation",
    value: 827,
  },
  {
    key: "Xbox",
    value: 828,
  },
  {
    key: "Indoors",
    value: 829,
  },
  {
    key: "Environmental Engineering",
    value: 830,
  },
  {
    key: "Courier Service",
    value: 831,
  },
  {
    key: "AgTech",
    value: 832,
  },
  {
    key: "Edge computing",
    value: 833,
  },
  {
    key: "Windows Phone",
    value: 834,
  },
  {
    key: "Debt Collections",
    value: 835,
  },
  {
    key: "TV",
    value: 836,
  },
  {
    key: "Traffic engineering",
    value: 837,
  },
  {
    key: "Volley Ball",
    value: 838,
  },
  {
    key: "Genetics",
    value: 839,
  },
  {
    key: "Waste Management",
    value: 840,
  },
  {
    key: "Group Buying",
    value: 841,
  },
  {
    key: "Business Information Systems",
    value: 842,
  },
  {
    key: "Home Decor",
    value: 843,
  },
  {
    key: "Dental",
    value: 844,
  },
  {
    key: "Public Relations",
    value: 845,
  },
  {
    key: "Lithium iron phosphate battery",
    value: 846,
  },
  {
    key: "Cloud Security",
    value: 847,
  },
  {
    key: "Retail Technology",
    value: 848,
  },
  {
    key: "Windows",
    value: 849,
  },
  {
    key: "International development",
    value: 850,
  },
  {
    key: "Crowdsourcing",
    value: 851,
  },
  {
    key: "Scheduling",
    value: 852,
  },
  {
    key: "Automotive",
    value: 853,
  },
  {
    key: "Life Insurance",
    value: 854,
  },
  {
    key: "Smart Building",
    value: 855,
  },
  {
    key: "Research and development",
    value: 856,
  },
  {
    key: "Web Design",
    value: 857,
  },
  {
    key: "Energy industry",
    value: 858,
  },
  {
    key: "Celebrity",
    value: 859,
  },
  {
    key: "Solar / Home Services",
    value: 860,
  },
  {
    key: "Continuing Education",
    value: 861,
  },
  {
    key: "Telecommunications",
    value: 862,
  },
  {
    key: "Communications Infrastructure",
    value: 863,
  },
  {
    key: "Housekeeping Service",
    value: 864,
  },
  {
    key: "Linux",
    value: 865,
  },
  {
    key: "Inspection",
    value: 866,
  },
  {
    key: "Electric industrial battery manufacturing",
    value: 867,
  },
  {
    key: "Product Search",
    value: 868,
  },
  {
    key: "Bakery",
    value: 869,
  },
  {
    key: "Bicycle",
    value: 870,
  },
  {
    key: "Government technology",
    value: 871,
  },
  {
    key: "Finance",
    value: 872,
  },
  {
    key: "Flash Storage",
    value: 873,
  },
  {
    key: "WebOS",
    value: 874,
  },
  {
    key: "Cyber-physical system",
    value: 875,
  },
  {
    key: "Augmented Reality",
    value: 876,
  },
  {
    key: "Adtech",
    value: 877,
  },
  {
    key: "Casino",
    value: 878,
  },
  {
    key: "Vertical Takeoff and Landing (VTOL)",
    value: 879,
  },
  {
    key: "Dating",
    value: 880,
  },
  {
    key: "Automotive engineering",
    value: 881,
  },
  {
    key: "Credit",
    value: 882,
  },
  {
    key: "Market Research",
    value: 883,
  },
  {
    key: "3D Printing",
    value: 884,
  },
  {
    key: "Parks",
    value: 885,
  },
  {
    key: "Electronic Health Record (EHR)",
    value: 886,
  },
  {
    key: "Robot-enabled delivery",
    value: 887,
  },
  {
    key: "Coaching",
    value: 888,
  },
  {
    key: "Consumer Lending",
    value: 889,
  },
  {
    key: "Self-driving vehicle",
    value: 890,
  },
  {
    key: "Baseball",
    value: 891,
  },
  {
    key: "Surveillance",
    value: 892,
  },
  {
    key: "Software",
    value: 893,
  },
  {
    key: "Autonomous underwater vehicle",
    value: 894,
  },
  {
    key: "Gear manufacturing",
    value: 895,
  },
  {
    key: "Aviation",
    value: 896,
  },
  {
    key: "Biometrics",
    value: 897,
  },
  {
    key: "Table Tennis",
    value: 898,
  },
  {
    key: "Social Network",
    value: 899,
  },
  {
    key: "Podcast",
    value: 900,
  },
  {
    key: "Indoor positioning system",
    value: 901,
  },
  {
    key: "Equestrian",
    value: 902,
  },
  {
    key: "Storage",
    value: 903,
  },
  {
    key: "Venture capital firm",
    value: 904,
  },
  {
    key: "Crowdfunding",
    value: 905,
  },
  {
    key: "Document Management",
    value: 906,
  },
  {
    key: "Cloud Computing",
    value: 907,
  },
  {
    key: "Brand Marketing",
    value: 908,
  },
  {
    key: "Electric vehicle charging service",
    value: 909,
  },
  {
    key: "Commercial",
    value: 910,
  },
  {
    key: "Smart contract (blockchain)",
    value: 911,
  },
  {
    key: "Credit Cards",
    value: 912,
  },
  {
    key: "Virtual Currency",
    value: 913,
  },
  {
    key: "Music Streaming",
    value: 914,
  },
  {
    key: "Wind Energy",
    value: 915,
  },
  {
    key: "Utilities",
    value: 916,
  },
  {
    key: "Law Enforcement",
    value: 917,
  },
  {
    key: "Photo Sharing",
    value: 918,
  },
  {
    key: "Electric motor",
    value: 919,
  },
  {
    key: "Speech Recognition",
    value: 920,
  },
  {
    key: "Product Research",
    value: 921,
  },
  {
    key: "Virtual Assistant",
    value: 922,
  },
  {
    key: "Video Conferencing",
    value: 923,
  },
  {
    key: "Edutainment",
    value: 924,
  },
  {
    key: "Healthcare industry",
    value: 925,
  },
  {
    key: "Mobile phone",
    value: 926,
  },
  {
    key: "Resorts",
    value: 927,
  },
  {
    key: "Retail banking",
    value: 928,
  },
  {
    key: "Shipping",
    value: 929,
  },
  {
    key: "Food Processing",
    value: 930,
  },
  {
    key: "Guides",
    value: 931,
  },
  {
    key: "Taxi Service",
    value: 932,
  },
  {
    key: "DIY",
    value: 933,
  },
  {
    key: "Deep learning",
    value: 934,
  },
  {
    key: "Land transport",
    value: 935,
  },
  {
    key: "Printing",
    value: 936,
  },
  {
    key: "Transaction Processing",
    value: 937,
  },
  {
    key: "Machine vision",
    value: 938,
  },
  {
    key: "Military Engineering",
    value: 939,
  },
  {
    key: "Neuroscience",
    value: 940,
  },
  {
    key: "Electric vehicle parts manufacturers",
    value: 941,
  },
  {
    key: "Food and Beverage Retailers",
    value: 942,
  },
  {
    key: "Electric bicycle manufacturing",
    value: 943,
  },
  {
    key: "Telecommunications industry",
    value: 944,
  },
  {
    key: "Mobility Technology",
    value: 945,
  },
  {
    key: "Biofuel",
    value: 946,
  },
  {
    key: "Home Services",
    value: 947,
  },
  {
    key: "Space Travel",
    value: 948,
  },
  {
    key: "Music Label",
    value: 949,
  },
  {
    key: "Virtual Reality",
    value: 950,
  },
  {
    key: "Nightlife",
    value: 951,
  },
  {
    key: "Business software",
    value: 952,
  },
  {
    key: "Local",
    value: 953,
  },
  {
    key: "Home Health Care",
    value: 954,
  },
  {
    key: "Application programming interface (API)",
    value: 955,
  },
  {
    key: "Gift Card",
    value: 956,
  },
  {
    key: "Investor",
    value: 957,
  },
  {
    key: "3D Technology",
    value: 958,
  },
  {
    key: "Automotive industry",
    value: 959,
  },
  {
    key: "Reservations",
    value: 960,
  },
  {
    key: "Pet",
    value: 961,
  },
  {
    key: "Natural Language Processing",
    value: 962,
  },
  {
    key: "Telecommunications equipment",
    value: 963,
  },
  {
    key: "Facilities Support Services",
    value: 964,
  },
  {
    key: "Carsharing",
    value: 965,
  },
  {
    key: "Tutoring",
    value: 966,
  },
  {
    key: "Restaurants",
    value: 967,
  },
  {
    key: "Event Management",
    value: 968,
  },
  {
    key: "Warehousing",
    value: 969,
  },
  {
    key: "Innovation",
    value: 970,
  },
  {
    key: "Music Venues",
    value: 971,
  },
  {
    key: "CivicTech",
    value: 972,
  },
  {
    key: "Animal Health",
    value: 973,
  },
  {
    key: "Timeshare",
    value: 974,
  },
  {
    key: "Data Integration",
    value: 975,
  },
  {
    key: "Content Creators",
    value: 976,
  },
  {
    key: "CAD",
    value: 977,
  },
  {
    key: "Textiles",
    value: 978,
  },
  {
    key: "Consumer Software",
    value: 979,
  },
  {
    key: "Ridesharing",
    value: 980,
  },
  {
    key: "Fossil Fuels",
    value: 981,
  },
  {
    key: "Unmanned underwater vehicle",
    value: 982,
  },
  {
    key: "Journalism",
    value: 983,
  },
  {
    key: "E-book",
    value: 984,
  },
  {
    key: "Electric boat battery manufacturing",
    value: 985,
  },
  {
    key: "Enterprise",
    value: 986,
  },
  {
    key: "Oil and Gas",
    value: 987,
  },
  {
    key: "Application Specific Integrated Circuit (ASIC)",
    value: 988,
  },
  {
    key: "Automated storage and retrieval system",
    value: 989,
  },
  {
    key: "Snack Food",
    value: 990,
  },
  {
    key: "Young Adults",
    value: 991,
  },
  {
    key: "Paper Manufacturing",
    value: 992,
  },
  {
    key: "Hydroponics",
    value: 993,
  },
  {
    key: "Extermination Service",
    value: 994,
  },
  {
    key: "Image Recognition",
    value: 995,
  },
  {
    key: "Advanced Manufacturing",
    value: 996,
  },
  {
    key: "Distillery",
    value: 997,
  },
  {
    key: "Electric vehicle service",
    value: 998,
  },
  {
    key: "Video Streaming",
    value: 999,
  },
  {
    key: "Fuel Cell",
    value: 1000,
  },
  {
    key: "Advertising",
    value: 1001,
  },
  {
    key: "Primary Education",
    value: 1002,
  },
  {
    key: "Precision-guided munition",
    value: 1003,
  },
  {
    key: "Health Insurance",
    value: 1004,
  },
  {
    key: "Intellectual Property",
    value: 1005,
  },
  {
    key: "Technology",
    value: 1006,
  },
  {
    key: "Application Performance Management",
    value: 1007,
  },
  {
    key: "Propulsion",
    value: 1008,
  },
  {
    key: "Google",
    value: 1009,
  },
  {
    key: "Fuel",
    value: 1010,
  },
  {
    key: "Wearables",
    value: 1011,
  },
  {
    key: "Career Planning",
    value: 1012,
  },
  {
    key: "Nightclubs",
    value: 1013,
  },
  {
    key: "Sustainability",
    value: 1014,
  },
  {
    key: "Events",
    value: 1015,
  },
  {
    key: "Real Estate Investment",
    value: 1016,
  },
  {
    key: "Organic Food",
    value: 1017,
  },
  {
    key: "Recruitment",
    value: 1018,
  },
  {
    key: "Wired Telecommunications",
    value: 1019,
  },
  {
    key: "Translation Service",
    value: 1020,
  },
  {
    key: "Training",
    value: 1021,
  },
  {
    key: "Solar power",
    value: 1022,
  },
  {
    key: "Electric Vehicle",
    value: 1023,
  },
  {
    key: "Parenting",
    value: 1024,
  },
  {
    key: "Motorcycle",
    value: 1025,
  },
  {
    key: "Vertical SaaS",
    value: 1026,
  },
  {
    key: "Incubators",
    value: 1027,
  },
  {
    key: "Electric carsharing",
    value: 1028,
  },
  {
    key: "Bioinformatics",
    value: 1029,
  },
  {
    key: "Peer to Peer",
    value: 1030,
  },
  {
    key: "Carbon capture and storage",
    value: 1031,
  },
  {
    key: "Online Portals",
    value: 1032,
  },
  {
    key: "Food Delivery",
    value: 1033,
  },
  {
    key: "Solar charging manufacturing",
    value: 1034,
  },
  {
    key: "Embedded Software",
    value: 1035,
  },
  {
    key: "Data analysis",
    value: 1036,
  },
  {
    key: "Fraud Detection",
    value: 1037,
  },
  {
    key: "Rental Property",
    value: 1038,
  },
  {
    key: "Video on Demand",
    value: 1039,
  },
  {
    key: "Platform as a service (PaaS)",
    value: 1040,
  },
  {
    key: "Ad Retargeting",
    value: 1041,
  },
  {
    key: "Human Resources",
    value: 1042,
  },
  {
    key: "Machinery Manufacturing",
    value: 1043,
  },
  {
    key: "Independent Music",
    value: 1044,
  },
  {
    key: "Electric vehicle manufacturing",
    value: 1045,
  },
  {
    key: "Customer Service",
    value: 1046,
  },
  {
    key: "Human Computer Interaction",
    value: 1047,
  },
  {
    key: "Lawn Mower",
    value: 1048,
  },
  {
    key: "Database",
    value: 1049,
  },
  {
    key: "Virtual World",
    value: 1050,
  },
  {
    key: "Geomatics",
    value: 1051,
  },
  {
    key: "Employment",
    value: 1052,
  },
  {
    key: "Hedge Funds",
    value: 1053,
  },
  {
    key: "Web Hosting",
    value: 1054,
  },
  {
    key: "Soccer",
    value: 1055,
  },
  {
    key: "Graphic Design",
    value: 1056,
  },
  {
    key: "GPS",
    value: 1057,
  },
  {
    key: "Cloud Storage",
    value: 1058,
  },
  {
    key: "Aircraft",
    value: 1059,
  },
  {
    key: "Social Recruiting",
    value: 1060,
  },
  {
    key: "Cannabis",
    value: 1061,
  },
  {
    key: "Ad Server",
    value: 1062,
  },
  {
    key: "Broadcasting",
    value: 1063,
  },
  {
    key: "Corrections Facilities",
    value: 1064,
  },
  {
    key: "Personalization",
    value: 1065,
  },
  {
    key: "Loyalty Programs",
    value: 1066,
  },
  {
    key: "Electric vehicle powertrain",
    value: 1067,
  },
  {
    key: "Fruit",
    value: 1068,
  },
  {
    key: "Call Center",
    value: 1069,
  },
  {
    key: "Air Transportation",
    value: 1070,
  },
  {
    key: "Web Development",
    value: 1071,
  },
  {
    key: "Generative AI",
    value: 1072,
  },
];
