import React from "react";
import { Avatar } from "@mui/material";

const COLORS = {
  "Active User (20/6)": "green",
  "Active User(8/2)": "blue",
  "Inactive User Nurturing": "red",
  "Logged In": "yellow",
  Registered: "purple",
};
const UserComponent = ({ lead }) => {
  return (
    <div className="col-6 p-1">
      <div>
        <div style={{ border: "1px" }}>
          <Avatar
            src={lead.avatar}
            sx={{
              width: 40,
              height: 40,
              padding: 0,
              color: COLORS[lead.userCategory],
            }}
          >
            {lead.name && lead.name[0] && lead.name[0]}
          </Avatar>
          <p style={{ fontSize: "14px" }}>
            {`${lead.name && lead.name.substring(0, 15)}`}
            <br />
            {`(${lead.firstname} ${lead.lastname}, ${lead.type})`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserComponent;
