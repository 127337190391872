import React from "react";
import Axios from "axios";

import { Avatar, CircularProgress } from "@mui/material";

export default function Activity() {
  const [activity, setactivity] = React.useState(null);

  const fetchStats = async () => {
    const { data } = await Axios({
      method: "get",
      url: "/api/activity/insights",
    });
    setactivity(data.data);
  };
  React.useEffect(() => {
    fetchStats();
  }, []);
  if (activity === null) {
    return <CircularProgress />;
  }
  return (
    <div className="container admin-user-activity">
      <h3>Insights Activity</h3>
      {activity ? (
        <>
          <table>
            <tr>
              <th className="white">User</th>
              <th className="white">Title</th>
              <th className="white">Bucket</th>
              <th className="white">Type</th>
              <th className="white">Time</th>
            </tr>
            {activity.length > 0 &&
              activity.map((res, i) => (
                <tr key={i} className="my-2 pt-2">
                  <td className="white my-2 pt-2">
                    <Avatar src={res.userid ? res.userid.avatar : ""} />
                    {res.userid
                      ? `${res.userid.name} (${res.userid.type})`
                      : ""}
                  </td>
                  <td className="white my-2 pt-2">{res.title}</td>
                  <td className="white my-2 pt-2">{res.bucket}</td>

                  <td className="white my-2 pt-2">{res.type}</td>

                  <td className="white">
                    {new Date(res.updatedAt).toLocaleTimeString()}{" "}
                    {new Date(res.updatedAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
          </table>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
