import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./index.css";
import Cards from "./FounderCards/Cards";
// import CardApi from "../API/FounderApi/CardApi";
import Axios from "axios";

const index = () => {
  const [corporates, setcorporates] = useState();
  const fetchNonVerifiedFounders = async () => {
    await Axios({
      method: "GET",
      url: "/api/admin/all-corporates",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        const { data } = res.data;
        setcorporates(data);
      })
      .catch(() => console.log("error"));
  };

  useEffect(() => {
    fetchNonVerifiedFounders();
  }, []);

  // if (!corporates) {
  //   return "empty";
  // }
  //   else
  // {
  return (
    <>
      <div className="admin-root container">
        <div className="zr-nav">
          <NavLink to="/zr-admin-dashboard" className="p_small_grey2">
            Dashboard
          </NavLink>
          {/* &#47; */}
          <span>&#47;</span>
          <NavLink to="/admin/verify-founder" className="p_small_grey2">
            Pending Corporates Verifications
          </NavLink>
        </div>
        <div className="main-card-div">
          <div className="founderCard">
            {corporates &&
              corporates.map((founder) => (
                <Cards key={founder.id} data={founder} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
  // }
};

export default index;
