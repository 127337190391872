import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./index.css";
import { CircularProgress } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { toast, ToastContainer } from "react-toastify";
import { Avatar } from "@mui/material";
import { Box, Modal, Pagination } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90%",
  width: "568px",
  borderRadius: "24px",
  bgcolor: "white",
  boxShadow: "2px 2px 8px rgba(83, 83, 83, 0.12)",
  padding: "32px",
};
const CronJobs = () => {
  const [plans, setPlans] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [plantype, setplantype] = useState("All");
  const [newplantype, setnewplantype] = useState("");
  const [newplanduration, setnewplanduration] = useState("1w");
  const [expirydate, setexpirydate] = useState(
    new Date() + 7 * 24 * 60 * 60 * 1000
  );
  const [sendPlanUpdatedEmail, setsendPlanUpdatedEmail] = useState(true);
  // const [sendReminders, setsendReminders] = useState(true);

  const [datetype, setdatetype] = useState("plan.startDate");
  const [Usertype, setUsertype] = useState("All");
  const [sorttype, setsorttype] = useState("latestregister");
  const [loader, setloader] = useState(false);

  const [startDate, setStartDate] = useState(new Date("Sun Jan 01 2022"));
  const [endDate, setEndDate] = useState(new Date());
  const [searchuser, setsearchuser] = useState("");
  const [fetchedusers, setfetchedusers] = useState([]);
  const [selectedUserId, setselectedUserId] = useState(null);
  const [currentuser, setcurrentuser] = useState(null);
  const fetchPlans = async () => {
    setloader(true);
    await Axios({
      method: "get",
      url: `/api/activity/newplans?type=${plantype}&usertype=${Usertype}&start=${startDate}&end=${endDate}&userid=${selectedUserId?._id}&datetype=${datetype}&sorttype=${sorttype}&page=${page}&size=20`,
    })
      .then((res) => {
        setPlans(res.data.data);
        setloader(false);
        setCount(res.data.count);
        setsearchuser("");
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };
  const fetchUserByName = async () => {
    try {
      // if (searchuser.trim() === "") {
      //   setselectedUserId(null);
      //   setfetchedusers([]);
      //   return;
      // }
      const response = await Axios.get(`/api/admin/searchuser?s=${searchuser}`);
      if (response.data.success) {
        setfetchedusers(response.data.data);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };
  const handleUpdatePlan = async () => {
    try {
      if (newplantype?.trim() === "") return alert("Select new plan");
      if (!confirm("Are you sure of updating this user's plan?")) return;
      const response = await Axios.post(`/api/admin/updateplan`, {
        newplantype,
        userid: currentuser?._id,
        newplanduration,
        newplanexpiry: expirydate,
        sendPlanUpdatedEmail,
      });
      if (response.data.success) {
        handleclose();
        toast.success("Plan updated successfully");
        setTimeout(() => {
          fetchPlans();
        }, 3000);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };

  useEffect(() => {
    fetchPlans();
  }, [
    plantype,
    Usertype,
    startDate,
    endDate,
    datetype,
    selectedUserId,
    sorttype,
    page,
  ]);
  useEffect(() => {
    if (searchuser?.trim() != "") fetchUserByName();
    else setfetchedusers([]);
  }, [searchuser]);

  const returnDate = (d) => {
    return new Date(d).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };
  const [open, setopen] = useState(false);
  const handleopen = (u) => {
    setcurrentuser(u);
    setopen(true);
  };
  const handleclose = () => setopen(false);
  function addTimeToDate(period) {
    const newDate = new Date();
    switch (period) {
      case "1w":
        newDate.setDate(newDate.getDate() + 7);
        break;
      case "1m":
        newDate.setMonth(newDate.getMonth() + 1);
        break;
      case "3m":
        newDate.setMonth(newDate.getMonth() + 3);
        break;
      case "6m":
        newDate.setMonth(newDate.getMonth() + 6);
        break;
      case "1y":
        newDate.setFullYear(newDate.getFullYear() + 1);
        break;
      default:
        throw new Error("Invalid period");
    }
    return newDate;
  }
  useEffect(() => {
    setexpirydate(addTimeToDate(newplanduration));
  }, [newplanduration]);
  return (
    <>
      <ToastContainer />
      <div className="plan_root mt-0 pt-0">
        <div className="d-flex align-items-center justify-content-between plan_dates">
          <div className="">
            <div>
              <p>Search User&apos;s Plan</p>
              <input
                style={{ width: "500px", padding: "8px" }}
                value={searchuser}
                className="h-40px"
                onChange={(e) => setsearchuser(e.target.value)}
                type="text"
                placeholder="Get timeline by name"
              />
            </div>
            {searchuser.length > 0 &&
              fetchedusers &&
              fetchedusers.length > 0 && (
                <div className="gd_explore_main_results_block">
                  <div
                    style={{ backgroundColor: "white" }}
                    className="thin_bg "
                  >
                    {fetchedusers.map((v, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          setselectedUserId(v);
                          setfetchedusers([]);
                        }}
                        className="gd_explore_main_result d-flex justify-content-between align-items-center mb-16 pointer"
                      >
                        <div className="d-flex align-items-center px-2 py-1">
                          <Avatar
                            src={v.avatar}
                            alt={v.name ?? v.firstname}
                            style={{ height: 40, width: 40 }}
                          />
                          <div style={{ color: "black" }} className="ms-3">
                            <p className="body1 mb-0">
                              {v.type === "member"
                                ? `${v.firstname} ${v.lastname}, (${v.type}) `
                                : `${v.name}, (${v.firstname} ${v.lastname}, ${v.type})`}

                              <span className="caption"> {v.email}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
          <div className="">
            <p className="" htmlFor="typeDropdown">
              Select User-type
            </p>
            <select
              id="typeDropdown"
              className="h-40px"
              value={Usertype}
              onChange={(e) => setUsertype(e.target.value)}
            >
              <option value="All">All</option>
              <option value="startup">Startup</option>
              <option value="investor">Investor</option>
              <option value="fundcompany">Fundcompany</option>
              <option value="corporate">Corporates</option>
              <option value="institute">Institutes</option>
              <option value="member">GD Member</option>
            </select>
          </div>
          <div className="">
            <p className="" htmlFor="typeDropdown">
              Select Plan
            </p>
            <select
              id="typeDropdown"
              className="h-40px"
              value={plantype}
              onChange={(e) => setplantype(e.target.value)}
            >
              <option value="All">All</option>
              <option value="Free">Free</option>
              <option value="Freemium">Freemium</option>
              <option value="Basic">Basic</option>
              <option value="Premium">Premium</option>
            </select>
          </div>
          <div className="">
            <p className="" htmlFor="typeDropdown">
              Sort
            </p>
            <select
              id="typeDropdown"
              className="h-40px"
              value={sorttype}
              onChange={(e) => setsorttype(e.target.value)}
            >
              <option value="recentexpire">Expiring/Expired Recently</option>
              <option value="increaseexpiry"> Ascending Expirydate</option>
              <option value="decreaseexpiry">Descending Expirydate</option>
              <option value="latestregister">Latest Registered</option>
            </select>
          </div>
          <div className="">
            <p> Timeline</p>
            <select
              id="typeDropdown"
              value={datetype}
              className="h-40px"
              onChange={(e) => setdatetype(e.target.value)}
            >
              <option value="plan.startDate">Plan Start</option>
              <option value="plan.endDate">Plan End</option>
            </select>
          </div>
          <div>
            <p> Start Date</p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                style={{ backgroundColor: "white" }}
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  width: "170px",
                  height: "40px",
                }}
                format="dd MMM yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Start Date"
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div>
            <p> End Date</p>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              sx={{ height: "40px" }}
            >
              <DatePicker
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  width: "170px",
                  height: "40px",
                }}
                format="dd MMM yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="End Date"
                    sx={{ height: "40px" }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>

        {loader && <CircularProgress />}
        <div className="plan_main">
          {selectedUserId ? (
            <>
              <p
                className="row align-items-center mx-0 mb-0 pointer"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  padding: "8px",
                  width: "50%",
                }}
              >
                <Avatar src={selectedUserId.avatar} />
                &nbsp;
                {`${selectedUserId.firstname} ${selectedUserId.lastname}, (${selectedUserId.name},${selectedUserId.type}) `}
              </p>
              <button
                onClick={() => setselectedUserId(null)}
                style={{ backgroundColor: "red" }}
              >
                X
              </button>
            </>
          ) : (
            ""
          )}
          {plans.length > 0 ? (
            <div style={{ width: "1550px" }}>
              <table className="plan_table mt-24" style={{ width: "2200px" }}>
                <thead className="plan_thead">
                  <tr className="plan_thead_tr plan_tr">
                    <th className="plan_thead_tr plan_td">Profile</th>
                    <th className="plan_thead_tr plan_td">Type</th>
                    <th className="plan_thead_tr plan_td">Signup</th>
                    <th className="plan_thead_tr plan_td">2FA Set</th>
                    <th className="plan_thead_tr plan_td">Plan Name</th>
                    <th className="plan_thead_tr plan_td">Start date</th>
                    <th className="plan_thead_tr plan_td">End date</th>
                    <th className="plan_thead_tr plan_td">
                      Basic/Premium Plan End Schedule
                    </th>
                    <th className="plan_thead_tr plan_td">
                      Freemium Plan End Schedule
                    </th>
                    <th className="plan_thead_tr plan_td">
                      Freemium Plan Expiry Reminder
                    </th>
                    <th className="plan_thead_tr plan_td">
                      Freemium Plan Renew Reminder
                    </th>
                  </tr>
                </thead>
                <tbody className="plan_tbody">
                  {plans.map((plan) => (
                    <tr key={plan._id} className="plan_tbody_tr plan_tr">
                      <td className="plan_tbody_td plan_td">
                        <div className="d-flex plan_tbody_logo align-items-center">
                          <Avatar src={plan?.avatar} alt="logo" />
                          <div className="ms-2">
                            <p className="m-0">
                              {plan?.name
                                ? plan.name
                                : `${plan?.firstname} ${plan?.lastname}`}
                            </p>
                            <p className="m-0 mt-8">{`${plan?.email}`}</p>
                          </div>
                        </div>
                      </td>
                      <td className="plan_tbody_td plan_td">{plan.type}</td>
                      <td className="plan_tbody_td plan_td">
                        {returnDate(plan.createdAt)}
                      </td>
                      <td
                        style={
                          plan.is2fAuth && plan.is2fAuth === true
                            ? { color: "green" }
                            : { color: "red" }
                        }
                        className="plan_tbody_td plan_td"
                      >
                        {plan.is2fAuth && plan.is2fAuth === true
                          ? "Done"
                          : "Not Yet"}
                      </td>
                      <td
                        onClick={() => handleopen(plan)}
                        className="plan_tbody_td plan_td pointer"
                      >
                        {plan?.plan.planName}
                      </td>
                      <td className="plan_tbody_td plan_td">
                        {new Date(plan?.plan.startDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </td>
                      <td className="plan_tbody_td plan_td">
                        {new Date(plan?.plan.endDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </td>
                      {plan.planjobs && plan.planjobs.length > 0 ? (
                        <>
                          <td className="plan_tbody_td plan_td">
                            {plan.planjobs
                              ?.filter((v) => v.name === "schedule-plan-dates")
                              .map((v, i) => {
                                if (v.status === "stopped")
                                  return (
                                    <>
                                      <li
                                        style={{ color: "lightgreen" }}
                                        key={i}
                                      >
                                        Success at {returnDate(v.lastRunAt)}
                                      </li>
                                    </>
                                  );
                                if (v.status === "delayed")
                                  return (
                                    <>
                                      <li style={{ color: "skyblue" }} key={i}>
                                        Scheduled at {returnDate(v.nextRunAt)}
                                      </li>
                                    </>
                                  );
                              })}
                          </td>
                          <td className="plan_tbody_td plan_td">
                            {plan.planjobs
                              ?.filter((v) => v.name === "changeplan-to-free")
                              .map((v, i) => {
                                if (v.status === "stopped")
                                  return (
                                    <>
                                      <li
                                        style={{ color: "lightgreen" }}
                                        key={i}
                                      >
                                        Success at {returnDate(v.lastRunAt)}
                                      </li>
                                    </>
                                  );
                                if (v.status === "delayed")
                                  return (
                                    <>
                                      <li style={{ color: "skyblue" }} key={i}>
                                        Scheduled at {returnDate(v.nextRunAt)}
                                      </li>
                                    </>
                                  );
                              })}
                          </td>
                          <td className="plan_tbody_td plan_td">
                            {plan.planjobs
                              ?.filter(
                                (v) => v.name === "plan-expiry-remainder"
                              )
                              .map((v, i) => {
                                if (v.status === "stopped")
                                  return (
                                    <>
                                      <li
                                        style={{ color: "lightgreen" }}
                                        key={i}
                                      >
                                        Success at {returnDate(v.lastRunAt)}
                                      </li>
                                    </>
                                  );
                                if (v.status === "delayed")
                                  return (
                                    <>
                                      <li style={{ color: "skyblue" }} key={i}>
                                        Scheduled at {returnDate(v.nextRunAt)}
                                      </li>
                                    </>
                                  );
                              })}
                          </td>
                          <td className="plan_tbody_td plan_td">
                            {plan.planjobs
                              ?.filter((v) => v.name === "renew-plan")
                              .map((v, i) => {
                                if (v.status === "stopped")
                                  return (
                                    <>
                                      <li
                                        style={{ color: "lightgreen" }}
                                        key={i}
                                      >
                                        Success at {returnDate(v.lastRunAt)}
                                      </li>
                                    </>
                                  );
                                if (v.status === "delayed")
                                  return (
                                    <>
                                      <li style={{ color: "skyblue" }} key={i}>
                                        Scheduled at {returnDate(v.nextRunAt)}
                                      </li>
                                    </>
                                  );
                              })}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="plan_tbody_td plan_td"></td>
                          <td className="plan_tbody_td plan_td"></td>
                          <td className="plan_tbody_td plan_td"></td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <h2>No Plans</h2>
          )}
        </div>
        <div className="pagination">
          <Pagination
            count={count ? Math.ceil(count / 20) : 0}
            page={page}
            onChange={(e, v) => setPage(v)}
          />
        </div>
      </div>
      <Modal
        className=" "
        open={open}
        onClose={handleclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {currentuser ? (
            <>
              <p className="bold">
                {`${currentuser.name}, ( ${currentuser.firstname} ${currentuser.lastname},${currentuser.type})`}
              </p>
              <p>
                {`Current Plan: ${currentuser.plan?.planName} ${
                  currentuser.plan?.planName !== "Free"
                    ? `, with expiry date ${returnDate(
                        currentuser.plan?.endDate
                      )}`
                    : ""
                } `}
              </p>
              <div>
                <label className="mb-3" htmlFor="typeDropdown">
                  Update Plan
                </label>
                <select
                  id="typeDropdown"
                  value={newplantype}
                  onChange={(e) => setnewplantype(e.target.value)}
                >
                  <option value="">Select New Plan</option>
                  <option value="Free">Free</option>
                  <option value="Freemium">Freemium</option>
                  <option
                    hidden={["corporate", "institute", "member"].includes(
                      currentuser.type
                    )}
                    value="Basic"
                  >
                    Basic
                  </option>
                  <option value="Premium">Premium</option>
                </select>
              </div>
              <div>
                <label className="mb-3" htmlFor="typeDropdown">
                  Plan Duration
                </label>
                <select
                  id="typeDropdown"
                  value={newplanduration}
                  onChange={(e) => setnewplanduration(e.target.value)}
                >
                  <option value="">Extend Plan Duration to</option>
                  <option value="1w">1 Week</option>
                  <option value="1m">1 Month</option>
                  <option value="3m">3 Months</option>
                  <option value="6m">6 Months</option>
                  <option value="1y">1 Year</option>
                </select>
              </div>
              <p>Confirm Expiry date</p>
              <div style={{ backgroundColor: "white", width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    style={{ backgroundColor: "white" }}
                    value={expirydate}
                    onChange={(newValue) => {
                      setexpirydate(newValue);
                    }}
                    format="dd MMM yyyy"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Expiry Date"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={sendPlanUpdatedEmail}
                    onChange={(e) => setsendPlanUpdatedEmail(e.target.checked)}
                  />
                  Send Plan Updated Email
                </label>
              </div>
              <br />
              <div>
                <button
                  onClick={() => handleUpdatePlan()}
                  className="gradient_admin_btn"
                >
                  Update Plan
                </button>
              </div>
              {/* <div>
                <label>
                  <input
                    type="checkbox"
                    checked={sendReminders}
                    onChange={(e) => setsendReminders(e.target.checked)}
                  />
                  Send Email on Expiry and Reminders
                </label>
              </div> */}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default CronJobs;
