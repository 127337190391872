import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/a.png";

import Axios from "axios";

import { toast } from "react-toastify";

const AdminDashboard = () => {
  const history = useNavigate();

  const logout = async () => {
    await Axios("/api/admin/logout")
      .then(() => {
        toast.success("Logged Out");
        history("/content-admin");
      })
      .catch(() => toast.error("Some Error"));
  };

  return (
    <>
      <div className="admin-root container">
        <div className="zr-nav-root">
          <figure>
            <img src={logo} className="img-fluid admin-img" alt="logo" />
          </figure>
          <p className="p_small_white">Hello Admin!</p> &nbsp;
          <button className="gradient_CTA" onClick={logout}>
            LOGOUT
          </button>
        </div>

        <div className="main-admin-container">
          <div className="admin-container">
            <div className="leftStats">
              <div className="leftReq">
                <div className="totalReq">
                  <h3>Articles</h3>
                  <div className="row">
                    <button
                      className="gradient_admin_btn col-5 mt-2"
                      onClick={() => history("/article-upload")}
                    >
                      Post Articles
                    </button>
                    <button
                      className="gradient_admin_btn col-5 mt-2"
                      onClick={() => history("/all-articles")}
                    >
                      View/Edit Articles
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
