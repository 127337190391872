import React from "react";
import {
  AccessRolesConst,
  AddtoSelected,
  getThisVal,
} from "../../../Utils/Roles";

const RoleComp = ({ isOpen, roles, searchVal, setIsOpen, setRoles, tref }) => {
  return (
    <>
      {isOpen && roles && Object.keys(roles).length > 0 ? (
        <div className="zefy-role-multiselect-wrapper" ref={tref}>
          {!roles[AccessRolesConst.admin_service] &&
            getThisVal(AccessRolesConst.admin_service, searchVal) && (
              <div className="zefy-role-multiselect-wrap">
                <p
                  className="body2 color_grey_46"
                  onClick={() =>
                    AddtoSelected(
                      AccessRolesConst.admin_service,
                      roles,
                      setRoles,
                      setIsOpen
                    )
                  }
                >
                  {AccessRolesConst.admin_service}
                </p>
              </div>
            )}
          {!roles[AccessRolesConst.valuation_service] &&
            getThisVal(AccessRolesConst.valuation_service, searchVal) &&
            !roles["Admin role"] && (
              <div className="zefy-role-multiselect-wrap">
                <p
                  className="body2 color_grey_46"
                  onClick={() =>
                    AddtoSelected(
                      AccessRolesConst.valuation_service,
                      roles,
                      setRoles,
                      setIsOpen
                    )
                  }
                >
                  {AccessRolesConst.valuation_service}
                </p>
              </div>
            )}
          {!roles[AccessRolesConst.pitchdeck_Service] &&
            getThisVal(AccessRolesConst.pitchdeck_Service, searchVal) &&
            !roles["Admin role"] && (
              <div className="zefy-role-multiselect-wrap">
                <p
                  className="body2 color_grey_46"
                  onClick={() =>
                    AddtoSelected(
                      AccessRolesConst.pitchdeck_Service,
                      roles,
                      setRoles,
                      setIsOpen
                    )
                  }
                >
                  {AccessRolesConst.pitchdeck_Service}
                </p>
              </div>
            )}
          {!roles[AccessRolesConst.insights_Service] &&
            getThisVal(AccessRolesConst.insights_Service, searchVal) &&
            !roles["Admin role"] && (
              <div className="zefy-role-multiselect-wrap">
                <p
                  className="body2 color_grey_46"
                  onClick={() =>
                    AddtoSelected(
                      AccessRolesConst.insights_Service,
                      roles,
                      setRoles,
                      setIsOpen
                    )
                  }
                >
                  {AccessRolesConst.insights_Service}
                </p>
              </div>
            )}
          {!roles[AccessRolesConst.event_Management] &&
            getThisVal(AccessRolesConst.event_Management, searchVal) &&
            !roles["Admin role"] && (
              <div className="zefy-role-multiselect-wrap">
                <p
                  className="body2 color_grey_46"
                  onClick={() =>
                    AddtoSelected(
                      AccessRolesConst.event_Management,
                      roles,
                      setRoles,
                      setIsOpen
                    )
                  }
                >
                  {AccessRolesConst.event_Management}
                </p>
              </div>
            )}
          {!roles[AccessRolesConst.partnership_Service] &&
            getThisVal(AccessRolesConst.partnership_Service, searchVal) &&
            !roles["Admin role"] && (
              <div className="zefy-role-multiselect-wrap">
                <p
                  className="body2 color_grey_46"
                  onClick={() =>
                    AddtoSelected(
                      AccessRolesConst.partnership_Service,
                      roles,
                      setRoles,
                      setIsOpen
                    )
                  }
                >
                  {AccessRolesConst.partnership_Service}
                </p>
              </div>
            )}
          {!roles[AccessRolesConst.gd_service] &&
            getThisVal(AccessRolesConst.gd_service, searchVal) &&
            !roles["Admin role"] && (
              <div className="zefy-role-multiselect-wrap">
                <p
                  className="body2 color_grey_46"
                  onClick={() =>
                    AddtoSelected(
                      AccessRolesConst.gd_service,
                      roles,
                      setRoles,
                      setIsOpen
                    )
                  }
                >
                  {AccessRolesConst.gd_service}
                </p>
              </div>
            )}
          {!roles[AccessRolesConst.recruitment_Service] &&
            getThisVal(AccessRolesConst.recruitment_Service, searchVal) &&
            !roles["Admin role"] && (
              <div className="zefy-role-multiselect-wrap">
                <p
                  className="body2 color_grey_46"
                  onClick={() =>
                    AddtoSelected(
                      AccessRolesConst.recruitment_Service,
                      roles,
                      setRoles,
                      setIsOpen
                    )
                  }
                >
                  {AccessRolesConst.recruitment_Service}
                </p>
              </div>
            )}
          {!roles[AccessRolesConst.connection_service] &&
            getThisVal(AccessRolesConst.connection_service, searchVal) &&
            !roles["Admin role"] && (
              <div className="zefy-role-multiselect-wrap">
                <p
                  className="body2 color_grey_46"
                  onClick={() =>
                    AddtoSelected(
                      AccessRolesConst.connection_service,
                      roles,
                      setRoles,
                      setIsOpen
                    )
                  }
                >
                  {AccessRolesConst.connection_service}
                </p>
              </div>
            )}
          {!roles[AccessRolesConst.mi_service] &&
            getThisVal(AccessRolesConst.mi_service, searchVal) &&
            !roles["Admin role"] && (
              <div className="zefy-role-multiselect-wrap">
                <p
                  className="body2 color_grey_46"
                  onClick={() =>
                    AddtoSelected(
                      AccessRolesConst.mi_service,
                      roles,
                      setRoles,
                      setIsOpen
                    )
                  }
                >
                  {AccessRolesConst.mi_service}
                </p>
              </div>
            )}
        </div>
      ) : null}
    </>
  );
};

export default RoleComp;
