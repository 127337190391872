import React from "react";

import Graphics from "./Graphics";



export default function PitchdeckGraphics() {
  
  return (
    <div className="admin-pd-root">
      <div className="container">
                <Graphics/>
           
          </div>
      </div>
  );
}
