const generatePassword = () => {
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const numberChars = "0123456789";
  const specialChars = "!@#%";
  const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;

  const length = Math.floor(Math.random() * 5) + 8;
  const uppercaseChar = uppercaseChars.charAt(
    Math.floor(Math.random() * uppercaseChars.length)
  );
  const lowercaseChar = lowercaseChars.charAt(
    Math.floor(Math.random() * lowercaseChars.length)
  );
  const numberChar = numberChars.charAt(
    Math.floor(Math.random() * numberChars.length)
  );
  const specialChar = specialChars.charAt(
    Math.floor(Math.random() * specialChars.length)
  );
  const otherChars = Array.from({ length: length - 4 }, () =>
    allChars.charAt(Math.floor(Math.random() * allChars.length))
  ).join("");

  return uppercaseChar + lowercaseChar + numberChar + specialChar + otherChars;
};

module.exports = generatePassword;
