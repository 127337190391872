/** @format */

import Axios from "axios";
import React, { useState } from "react";
import industries from "../../../Utils/Lists/Industies2.json";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";

import { ToastContainer, toast } from "react-toastify";

export default function Signup() {
  const history = useNavigate();

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const [imageurl, setimageurl] = useState("");
  const [previewImage, setpreviewImage] = useState(null);
  const [industry, setIndustry] = useState("");
  const [options] = useState(industries);
  const [link, setLink] = useState("");
  const addInputEvent3 = (e) => {
    setIndustry(e.map((i) => i.label));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    const data = {
      Subject: subject,
      Description: description,
      Image: imageurl,
      Industry: industry,
      Link: link,
    };
    await Axios.post("/api/adminlatest/post-news", data)
      .then(() => {
        toast.success("Submitted", { position: "bottom-right" });
        setSubject("");
        setDescription("");
        setIndustry("");
        setpreviewImage(null);
        setLink("");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const handleImage = async (e) => {
    const image = e.target.files[0];
    setpreviewImage(image);
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("image", image);
    Axios({
      method: "post",
      url: " /api/adminlatest/news-image",
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        setimageurl(data.location);
        toast.success("Image upload", { position: "bottom-right" });
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  return (
    <>
      <br />
      <br />

      <p
        className="p_small_white p_button"
        onClick={() => history("/zr-admin-dashboard")}
      >
        {" "}
        &nbsp;&nbsp;&nbsp;&nbsp;Back to dashboard
      </p>
      <div className="auth_root">
        <ToastContainer />
        <div className="container auth_container">
          <div className="auth_header">
            <h1 className="h2_white_30">LATEST NEWS</h1>
          </div>
          <div className="auth_form mb-5">
            <form onSubmit={handleOnSubmit}>
              <div className="row no-margin justify-content-space-evenly">
                <div className="no-left-padding">
                  <input
                    id="Subject"
                    placeholder="Subject"
                    onChange={(e) => setSubject(e.target.value)}
                    required
                    type="text"
                    className="auth_input"
                    value={subject}
                  />
                </div>
                <div className="no-left-padding">
                  <textarea
                    id="Description"
                    placeholder="Description"
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    style={{ height: "100px" }}
                    type="text"
                    className="auth_input"
                    value={description}
                  />
                </div>
                <input
                  id="Image"
                  placeholder="Image"
                  required
                  type="file"
                  className="auth_input"
                  onChange={(e) => handleImage(e)}
                />
                {previewImage && (
                  <img
                    style={{
                      maxHeight: "150px",
                      width: "auto",
                      margin: "10px 0",
                    }}
                    src={imageurl}
                  ></img>
                )}
                <Multiselect
                  isMulti
                  options={options}
                  className="auth_multi_select"
                  displayValue="label"
                  placeholder="Industries"
                  name="industries"
                  closeIcon="cancel"
                  value={industry}
                  onSelect={addInputEvent3}
                  onRemove={addInputEvent3}
                />
              </div>
              <div className="no-left-padding">
                <input
                  id="Link"
                  placeholder="Link"
                  onChange={(e) => setLink(e.target.value)}
                  required
                  style={{ height: "100px" }}
                  type="url"
                  className="auth_input"
                  value={link}
                />
              </div>

              <div className="loginbtns">
                <button className="auth_CTA gradient_CTA" type="submit">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
