/** @format */

import React, { useEffect, useState } from "react";
import "./responsePage.css";

import Axios from "axios";
import { toast } from "react-toastify";

export default function ResponsePage() {
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("user");

  const [valuations, setValuations] = useState();
  const [c, setc] = useState();

  const [reportUrl, setReportUrl] = useState(null);

  useEffect(() => {
    const fetchValuations = async () => {
      // const userId = query.get("user")

      await Axios({
        method: "get",
        url: `/api/admin/valuation/${userId}`,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((res) => {
          setValuations(res.data);
          setc(res.data.companyInfo);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchValuations();
  }, []);

  const handleUploadReport = (e) => {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("ValuationReport", file);

    Axios({
      method: "post",
      url: `/api/admin/uploadReport/${userId}`,

      data: formdata,
    })
      .then((res) => {
        setReportUrl(res.data);
        // console.log(res.data);
        localStorage.setItem("Report URL", res.data);
        toast.success("Report Uploaded");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSubmitReport = async () => {
    await Axios({
      method: "post",
      url: `/api/admin/submitReport/${userId}`,

      data: { downloadLink: reportUrl, user: valuations.user },
    })
      .then((res) => {
        if (res.data.success) toast.success("Report Submitted");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed");
      });
  };

  const onDeleteUrl = (e) => {
    e.preventDefault();
    setReportUrl("");
  };

  return (
    <div className="container text-center p_small_white">
      {valuations ? (
        <>
          <a className="" href="/admin/valuation">
            Back to valuations
          </a>
          <h1>Valuation for {valuations.companyName}</h1>
          {valuations &&
          valuations.companyInfo &&
          valuations.companyInfo.companyName ? (
            <div className="container">
              <h3>Company Info</h3>

              <div className="response-section">
                <div className="response-pair">
                  <div
                    style={{
                      borderBottom: "1px solid #f2f2f2",
                      marginTop: "5px",
                    }}
                  >
                    <h6>Company Name</h6>
                    <p>{c.companyName}</p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Company Logo</h6>
                    <img height={100} width={100} src={c.companylogo} />
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Company Description</h6>
                    <p>{c.description}</p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Company Address</h6>
                    <p>{c.address}</p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Establishment Year</h6>
                    <p>
                      {c.establishmentyear
                        ? new Date(c.establishmentyear).toString()
                        : null}
                    </p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>No of Employees</h6>
                    <p>{c.noofemployees}</p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Company Industry</h6>
                    <p>{c.industry}</p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Business Activity</h6>
                    <p>{c.businessactivity}</p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Funding Status</h6>
                    <p>{c.fundingstatus}</p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Last revenue generated</h6>
                    <p>{c.revenuegenerated}</p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Company Website</h6>
                    <p>{c.website}</p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Linkedin</h6>
                    <p>{c.linkedin}</p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Twitter</h6>
                    <p>{c.twitter}</p>
                  </div>
                  <div className="response-pair">
                    {" "}
                    <h6>Facebook</h6>
                    <p>{c.facebook}</p>
                  </div>{" "}
                  <h6>Shareholders</h6>
                  {c.shareholders.map((item, i) => (
                    <div
                      key={i}
                      className="response-pair finanacial-reponse row"
                    >
                      <div className="col-2" style={{ margin: "10px" }}>
                        <h6>Shareholder name</h6>
                        <p>{item.shareholdername}</p>
                      </div>
                      <div className="col-2" style={{ margin: "10px" }}>
                        <h6>Shareholder percentage</h6>
                        <p>{item.shareholderpercentage}%</p>
                      </div>
                    </div>
                  ))}
                  <h6>Competitors</h6>
                  {c.competitors.map((item, i) => (
                    <div
                      key={i}
                      className="response-pair finanacial-reponse row"
                    >
                      <div className="col-2" style={{ margin: "10px" }}>
                        <h6>Competitor name</h6>
                        <p>{item.competitorname}</p>
                      </div>
                      <div className="col-2" style={{ margin: "10px" }}>
                        <h6>Competitor website</h6>
                        <p>{item.competitorurl}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          <div className="container">
            <h3>Questionnaire</h3>
            <div className="response-section">
              <p className="p_small_white font20 p-3"> Team</p>
              {valuations.Team.map((item, i) => (
                <div key={i} className="response-pair">
                  <h6>{item.field_label}</h6>
                  <p>{item.field_value}</p>
                </div>
              ))}
            </div>
            <div className="response-section">
              <h4>Business Model</h4>
              {valuations.BusinessModel.map((item, i) => (
                <div key={i} className="response-pair">
                  <h6>{item.field_label}</h6>
                  <p>{item.field_value}</p>
                </div>
              ))}
            </div>
            <div className="response-section">
              <h4>Product Market</h4>
              {valuations.ProductMarket.map((item, i) => (
                <div key={i} className="response-pair">
                  <h6>{item.field_label}</h6>
                  <p>{item.field_value}</p>
                </div>
              ))}
            </div>
            <div className="response-section">
              <h4>IP Legal</h4>
              {valuations.IPLegal.map((item, i) => (
                <div key={i} className="response-pair">
                  <h6>{item.field_label}</h6>
                  <p>{item.field_value}</p>
                </div>
              ))}
            </div>
            <h3>Financials</h3>
            <div className="response-section">
              {valuations.fields.map((item, i) => (
                <div key={i} className="response-pair finanacial-reponse row">
                  <div className="col-2" style={{ margin: "10px" }}>
                    <h6>{item.row_label}</h6>
                  </div>
                  <div className="col-2" style={{ margin: "10px" }}>
                    <h6>Past Year</h6>
                    <p>{item.past_year.value}</p>
                  </div>
                  <div className="col-2" style={{ margin: "10px" }}>
                    <h6>Forecast</h6>
                    <p>{item.forcast.value}</p>
                  </div>
                  <div className="col-2" style={{ margin: "10px" }}>
                    <h6>Forecast1</h6>
                    <p>{item.forcast1.value}</p>
                  </div>

                  <div className="col-2" style={{ margin: "10px" }}>
                    <h6>Forecast2</h6>
                    <p>{item.forcast2.value}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="response-section">
              <h4>Assets</h4>
              {valuations.assets.map((item, i) => (
                <div key={i} className="response-pair">
                  <h6>{item.field_label}</h6>
                  <p>{item.field_value}</p>
                </div>
              ))}
            </div>
            <div className="response-section">
              <h4>Liabilities</h4>
              {valuations.liabilities.map((item, i) => (
                <div key={i} className="response-pair">
                  <h6>{item.field_label}</h6>
                  <p>{item.field_value}</p>
                </div>
              ))}
            </div>
            <h3>Transactions</h3>
            <div className="response-section">
              {valuations.contents.map((item, i) => (
                <div key={i} className="response-pair">
                  <h6>{item.h1}</h6>
                  {item.fields.map((field, i) => (
                    <div
                      key={i}
                      style={{
                        borderBottom: "1px solid #f2f2f2",
                        marginTop: "5px",
                      }}
                    >
                      <h6>{field.field_title}</h6>
                      <p>{field.fieldvalue}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <h4>Funding Rounds</h4>
            <div className="response-section">
              {/* <h4>Funding Rounds</h4> */}
              {valuations.fundingRounds.map((item, i) => (
                <div key={i} className="response-pair row">
                  <div className="col-3">
                    <h6>Equity</h6>
                    <p>{item.equity}</p>
                  </div>
                  <div className="col-3">
                    <h6>Capital</h6>
                    <p>{item.capitalRaised}</p>
                  </div>
                  <div className="col-3">
                    <h6>Valuation</h6>
                    <p>{item.valuation}</p>
                  </div>
                  <div className="col-3">
                    <h6>Closing Date</h6>
                    <p>{item.closingDate}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}

      <div className="response-section" style={{ padding: "30px" }}>
        <h4>Upload Equidam Report</h4>
        {!reportUrl ? (
          <>
            <input
              type="file"
              accept="application/pdf"
              name="report"
              id="report"
              onChange={handleUploadReport}
            />
          </>
        ) : (
          <>
            <a className="" target="_blank" rel="noreferrer" href={reportUrl}>
              Generated Report
            </a>
          </>
        )}

        <br />
        <br />
        <button className="btn btn-danger" onClick={(e) => onDeleteUrl(e)}>
          Remove
        </button>

        <button
          className="btn btn-success"
          style={{ fontSize: "15px", marginLeft: "20px" }}
          onClick={(e) => handleSubmitReport(e)}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
