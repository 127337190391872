import React, { useState, useRef } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import z_logo from "../../Assets/Images/Zefyron_logo_black_W400px.png";

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useAuth } from "../../Utils/Auth";
const AdminLogin = () => {
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const { setadminEmail, getsuperAdmin } = useAuth();

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const [calltimer, setcalltimer] = useState(false);
  const [code, setCode] = useState(null);
  const [otpStatus, setotpStatus] = useState(null);
  const sendOtp = async () => {
    if (calltimer) {
      toast.error("Please wait for sometime before requesting another OTP", {
        position: "bottom-right",
      });
    } else {
      Axios({
        method: "GET",
        url: `/api/admin/request/${email}`,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }).then((response) => {
        if (response.data.success) {
          toast.success("OTP Sent Successfully", { position: "bottom-right" });
          setotpStatus("sent");
          setcalltimer(true);
        } else {
          toast.error("Retry after some time", {
            position: "bottom-right",
          });
        }
      });
    }
  };
  const verifyOtp = async () => {
    if (!code || code.length < 4) {
      toast.error("Please Enter Valid OTP", { position: "bottom-right" });
    } else {
      Axios({
        method: "POST",
        url: `/api/admin/verify`,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: { code, email, password },
      })
        .then((response) => {
          if (response.data.status === "approved") {
            getsuperAdmin();
            toast.success("OTP Verified Successfully", {
              position: "bottom-right",
            });
            history("/zr-admin-dashboard");
            setotpStatus("verified");
            setCode(null);
          } else if (response.data.status === "pending") {
            toast.error("Invalid OTP", { position: "bottom-right" });
            setotpStatus("invalid");
            setCode(null);
          } else {
            toast.error(response.message, { position: "bottom-right" });
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            toast.error("Invalid Credentials!");
        });
    }
  };
  const RenderTime = ({ remainingTime }) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
      isNewTimeFirstTick.current = true;
      prevTime.current = currentTime.current;
      currentTime.current = remainingTime;
    } else {
      isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
      setTimeout(() => {
        setOneLastRerender((val) => val + 1);
      }, 20);
      setcalltimer(false);
    }

    const isTimeUp = isNewTimeFirstTick.current;
    return (
      <div className="time-wrapper">
        <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
          <p>{remainingTime}</p>
        </div>
      </div>
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email,
      password,
    };
    setadminEmail(email);
    await Axios.post("/api/admin/login", data)
      .then((res) => {
        if (res.status === 200) sendOtp();
      })
      .catch((error) => {
        if (error.response.status === 401) toast.error("Invalid Credentials!");
      });
  };
  return (
    <>
      <div className="auth_root">
        <div className="d-flex mt-2 align-items-center">
          <img src={z_logo} className="h-40px" alt="logo" />
        </div>
        <div className="container auth_container">
          <div className="auth_header">
            <h1 className="popping_ff">Admin Login</h1>
          </div>
          <div className="auth_form">
            <form onSubmit={handleSubmit}>
              <input
                id="outlined-basic"
                placeholder="Enter your email"
                required
                type="email"
                className="auth_input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
              <label className="auth_label">
                <input
                  className="auth_input auth_input_password"
                  placeholder="Enter your password"
                  required
                  type={!values.showPassword ? "password" : "text"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  className="auth_input_pas_btn"
                  type="button"
                  onClick={() => handleClickShowPassword()}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </button>
              </label>
              <div>
                <div className="row no-margin align-items-center justify-content-between">
                  <>
                    {otpStatus === "sent" ? (
                      <>
                        <div className="row no-margin align-items-center no-padding ">
                          <p className="m-0 text-left ps-0 my-2">
                            OTP sent to admin phone number
                          </p>
                          <input
                            type="number"
                            className="auth_input w_49 black"
                            placeholder="OTP"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />{" "}
                          <p className="m-0 text-left ps-0 my-2">
                            Didn&apos;t get the OTP?{" "}
                            <span
                              className="width_auto blue_23  no-margin p_button no-padding"
                              onClick={() => sendOtp()}
                            >
                              Resend
                            </span>
                          </p>
                          <button
                            type="button"
                            onClick={verifyOtp}
                            className="w_25 gradient_CTA"
                          >
                            Verify OTP
                          </button>
                        </div>
                      </>
                    ) : null}
                  </>
                  &nbsp; &nbsp;
                  {calltimer ? (
                    <div className="timer-wrapper" style={{ display: "none" }}>
                      <CountdownCircleTimer
                        isPlaying
                        duration={60}
                        size={50}
                        strokeWidth={5}
                        colors={[
                          ["#870015", 0.33],
                          ["#222", 0.33],
                          ["#123769"],
                        ]}
                      >
                        {RenderTime}
                      </CountdownCircleTimer>
                    </div>
                  ) : null}
                </div>

                {otpStatus === "verified" ? (
                  <p className="p_small_white">OTP Verified Successfully</p>
                ) : null}

                {otpStatus === "invalid" ? (
                  <>
                    <div className="row no-margin align-items-center">
                      <p className="p_small_white text-left">Invalid OTP</p>
                      <input
                        type="number"
                        className="auth_input w_49"
                        placeholder="OTP"
                        onChange={(e) => setCode(e.target.value)}
                      />{" "}
                      &nbsp; &nbsp;
                      <p className="width_auto p_small_whiteGray no-margin">
                        Didn&apos;t get the OTP?{" "}
                      </p>
                      <p
                        className="width_auto p_small_white no-margin p_button no-padding"
                        onClick={() => sendOtp()}
                      >
                        Resend
                      </p>
                      <button
                        type="button"
                        onClick={verifyOtp}
                        className="w_25 gradient_CTA"
                      >
                        Verify OTP
                      </button>
                    </div>
                    <br />
                  </>
                ) : null}
                <br />
              </div>

              <br />
              {!otpStatus ? (
                <div className="loginbtns">
                  <button className="auth_CTA gradient_CTA" type="submit">
                    LOGIN
                  </button>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
