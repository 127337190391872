import { Avatar } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import { AccessRoles, AccessRolesConst } from "../../../../Utils/Roles";
import { toast } from "react-toastify";
import SelectedRole from "../../Members/SelectedRole";
import RoleComp from "../../Members/RoleComp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  borderRadius: "10px",
  bgcolor: "background.paper",
  boxShadow: 24,

  p: 4,
};
export default function Corporates() {
  const [allcorps, setallcorps] = useState([]);
  const [selectCorp, setSelectCorp] = useState(null);
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState(AccessRoles);
  const [searchVal, setSearchVal] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchCorps = async () => {
    await axios({ url: "/api/corporate/all", method: "get" })
      .then((res) => {
        if (res.data.success) {
          setallcorps(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    if (email === null || email === " ") {
      toast.error("Email can not be empty", { position: "bottom-right" });
      return;
    }
    if (
      !roles[AccessRolesConst.admin_service] &&
      !roles[AccessRolesConst.valuation_service] &&
      !roles[AccessRolesConst.pitchdeck_Service] &&
      !roles[AccessRolesConst.insights_Service] &&
      !roles[AccessRolesConst.event_Management] &&
      !roles[AccessRolesConst.partnership_Service] &&
      !roles[AccessRolesConst.gd_service] &&
      !roles[AccessRolesConst.recruitment_Service] &&
      !roles[AccessRolesConst.connection_service] &&
      !roles[AccessRolesConst.mi_service]
    ) {
      toast.error("Role can not be empty", { position: "bottom-right" });
      return;
    }
    if (selectCorp && selectCorp._id) {
      await axios({
        method: "POST",
        url: `/api/corporate/invite/${selectCorp._id}`,
        data: {
          email: email,
          role: roles,
          user: selectCorp.id,
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            toast.success("Invite sent", { position: "bottom-right" });
            handleClose();
            setEmail("");
            setRoles(AccessRoles);
          } else {
            toast.error("Email already registered", {
              position: "bottom-right",
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, { position: "bottom-right" });
          if (err.response.status === 401) {
            toast.error("Unauthorized");
          }
        });
    } else {
      toast.error("No corporate selected");
    }
  };

  useEffect(() => {
    fetchCorps();
  }, []);

  return (
    <div className="container text-center p-4">
      <div className="col-3">
        <h1
          className="gradient_admin_btn"
          onClick={() => history("/zr-admin-dashboard")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-left"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
          Dashboard
        </h1>
      </div>
      <h1 className="white">Corporates</h1>
      <div className="row">
        {allcorps &&
          allcorps.length > 0 &&
          allcorps.map((corps, i) => (
            <div key={i} className="col-3 companyCard">
              <Avatar src={corps.logo} />
              <p className="white">{corps.nameOfCorporateType}</p>
              <p className="white">
                {corps.userJoined && corps.userJoined.length}/
                {corps.companySize}
              </p>

              <Button
                onClick={() => {
                  handleOpen();
                  setSelectCorp(corps);
                }}
              >
                ADD MEMBERS
              </Button>
            </div>
          ))}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleInvite} onClick={() => setIsOpen(false)}>
            {selectCorp && (
              <div>
                <Avatar src={selectCorp.logo} />
                <h1>{selectCorp.nameOfCorporateType}</h1>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="pf-company-input">
                  <label htmlFor="email" className="body1 mb-8 w-100">
                    Email
                  </label>
                  <input
                    required
                    type="mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="body2 w-100"
                    placeholder="email@abc.xyz"
                  />
                </div>
              </div>
              <div className="col-12 mt-24">
                <div
                  className="zefy-role-multiselect-root"
                  onClick={(e) => e.stopPropagation()}
                >
                  <label htmlFor="role" className="body1 mb-8 w-100">
                    Role
                  </label>
                  <div className="zefy-role-multiselect">
                    <input
                      type="text"
                      id="role"
                      name="role"
                      value={searchVal}
                      className="w-100"
                      onFocus={() => setIsOpen(true)}
                      // onBlur={()=>searchVal.length > 0 ? null : setIsOpen(false)}
                      onChange={(e) => setSearchVal(e.target.value)}
                      placeholder="Select Role"
                    />
                    <span className="zefy-role-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="8"
                        fill="none"
                        viewBox="0 0 12 8"
                      >
                        <path
                          fill="#404654"
                          d="M11.131.688H.87c-.308 0-.48.325-.29.546l5.132 5.95c.147.17.43.17.578 0l5.131-5.95c.19-.222.02-.546-.289-.546z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <RoleComp
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setRoles={setRoles}
                    roles={roles}
                    searchVal={searchVal}
                    // tref={ref}
                  />
                  <SelectedRole roles={roles} setRoles={setRoles} />
                </div>
              </div>
            </div>
            <Button className="primary_CTA03 mt-3" onClick={handleInvite}>
              Invite
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
