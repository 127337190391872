// import React, { useEffect, useState } from "react";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import CardApi from "../API/FounderApi/CardApi";
import "./index.css";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";

import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";

// import PhoneIcon from "@mui/icons-material/Phone";

// import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
// import ImageIcon from "@mui/icons-material/Image";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const index = () => {
  const location = useLocation();
  const history = useNavigate();
  const { data } = location.state;
  const [fundcompany] = useState(data);
  const [verified, setVerify] = useState(false);
  const [decline, setDecline] = useState(false);
  const [document, setdocument] = useState();
  const [review, setreview] = useState();
  // const { fundcompany } = useParams();
  // const [data, setData] = useState();

  // useEffect(() => {
  //   const newData = CardApi.filter((mydata) => {
  //     return mydata.handle === fundcompany;
  //   });
  //   setData(newData[0]);
  // }, []);

  // verify fundcompany
  const handleVerify = async () => {
    if (!confirm("Are you sure you want to Verify this?")) {
      return;
    }
    await Axios.post("/api/admin/handle-fc/accept", fundcompany)
      .then(() => {
        toast.success("Successfully Verified");
        setVerify(true);
        history("/admin/verify-fundcompany");
      })
      .catch(() => {
        toast.error("Some Error");
        setVerify(false);
      });
  };

  // decline fundcompany
  const handleDecline = async () => {
    if (!confirm("Are you sure you want to Decline this?")) {
      return;
    }
    await Axios.post("/api/admin/handle-fc/decline", fundcompany)
      .then(() => {
        toast.warning("Successfully Declined");
        setDecline(true);
        setTimeout(() => {
          history("/admin/verify-fundcompany");
        }, 500);
      })
      .catch(() => {
        toast.error("Some Error");
        setDecline(false);
      });
  };
  // const date = new Date(fundcompany.incorporationDate);
  if (!fundcompany) {
    return "Empty";
  }
  const fetchDocument = async () => {
    await Axios({
      method: "GET",
      url: `/api/admin/documents/${fundcompany.id}`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        setdocument(res.data.documents);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  const sendReview = async () => {
    if (!confirm("Are you sure you want to Send this review?")) {
      return;
    }
    const data = {
      id: fundcompany.id,
      reason: review,
    };
    await Axios.post("/api/admin/review", data)
      .then(() => {
        toast.success("Successfully Sent Review");
      })
      .catch(() => {
        toast.error("some error");
      });
  };
  return (
    <>
      <div className="admin-root container">
        <div className="founder-verify-main">
          <div className="verify-founder-head">
            <div className="verify-founder-head-img">
              <figure>
                <img
                  src={fundcompany.profilePic}
                  alt={fundcompany.companyName}
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="verify-founder-head-name">
              <p className="verify-founder-company-name">
                {fundcompany.companyName}
              </p>
              <p className="verify-founder-company-loc">
                {fundcompany.location.country}
              </p>
              {/* <p className="verify-founder-company-date">
                {`${date.toDateString()}`}
              </p> */}
              <a
                href={fundcompany.website}
                target="_blank"
                rel="noreferrer"
                className="verify-founder-company-comweb"
              >
                {fundcompany.website}
              </a>
            </div>
            <div className="verify-founder-head-blank"></div>
          </div>
          <hr className="verify-founder-hr" />
          <div className="verify-founder-info">
            <div className="verify-founder-infomain">
              <AccountBalanceRoundedIcon />
              TARGET SIZE : <p>{fundcompany.targetSize}</p>
            </div>

            <div className="verify-founder-infomain">
              <TimelineRoundedIcon />
              FUND RAISING STATUS <p>{fundcompany.fundRaisingStatus}</p>
            </div>
          </div>
          <hr className="verify-founder-hr" />
          <div className="w_100">
            <p>Documents</p>
          </div>
          <div className="verify-founder-doc-div">
            <div className="verify-founder-doc">
              {document &&
                document.documents.map((v, i) => {
                  return (
                    <>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        key={i}
                        href={v.location}
                        style={{ textDecoration: "none", color: "#fff" }}
                        className="gradient_admin_btn"
                      >
                        View Document
                      </a>
                      <br />
                    </>
                  );
                })}
              <div className="verify-founder-review">
                <input
                  type="text"
                  name=""
                  id=""
                  className="reviewInput"
                  placeholder="Send review to the Founder"
                  onChange={(e) => setreview(e.target.value)}
                  value={review}
                />
                <div>
                  <button className="black_CTA_view">ADD REVIEW</button>
                  <button
                    onClick={() => sendReview()}
                    className="gradient_admin_btn"
                  >
                    SEND ALL REVIEW
                  </button>
                </div>
              </div>
            </div>
            <div className="verify-founder-profile">
              <p>EMAIL:{fundcompany.contact.email}</p>
              <div className="verifybtn">
                {decline ? (
                  <button className="black_CTA_view">DECLINED</button>
                ) : (
                  <button className="black_CTA_view" onClick={handleDecline}>
                    DECLINE
                  </button>
                )}

                {verified ? (
                  <button className="black_CTA_view">
                    <DoneAllIcon />
                    VERIFIED
                  </button>
                ) : (
                  <button className="black_CTA_view" onClick={handleVerify}>
                    <DoneAllIcon />
                    VERIFY
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default index;
