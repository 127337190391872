export const AccessRolesConst = {
  valuation_service: "Valuation Service",
  pitchdeck_Service: "Pitchdeck Service",
  insights_Service: "Insights Service",
  event_Management: "Event Management",
  partnership_Service: "Partnership Service",
  gd_service: "Global Database Tool",
  recruitment_Service: "Recruitment Service",
  connection_service: "Connection and communication",
  mi_service: "Blogs and Market Intelligence Reports",
  admin_service: "Admin role",
};

export const AccessRoles = {
  "Valuation Service": false,
  "Pitchdeck Service": false,
  "Insights Service": false,
  "Event Management": false,
  "Partnership Service": false,
  "Global Database Tool": false,
  "Recruitment Service": false,
  "Connection and communication": false,
  "Blogs and Market Intelligence Reports": false,
  "Admin role": false,
};

export const getThisVal = (value, searchVal) => {
  if (searchVal.trim()) {
    return String(value).toLowerCase().includes(searchVal.toLowerCase());
  } else {
    return true;
  }
};

// for is Lock
export const handleRole = (user, service) => {
  if (user && user.accessRole) {
    if (user.accessRole[AccessRolesConst.admin_service]) {
      return false;
    } else if (user.accessRole[service]) {
      return false;
    } else {
      return true;
    }
  }
};

// for showing slider
export const handleSlider = (user, service, count) => {
  if (count > 0) {
    return false;
  } else if (
    user.type === "fundcompany" ||
    user.type === "corporate" ||
    user.type === "institute"
  )
    return true;
  else if (user && user.accessRole) {
    if (user.accessRole[AccessRolesConst.admin_service]) {
      return true;
    } else if (user.accessRole[service]) {
      return true;
    } else {
      return false;
    }
  }
};

// add to roles
export const AddtoSelected = (arr, roles, setRoles, setIsOpen) => {
  if (arr === AccessRolesConst.admin_service) {
    setRoles({ ...AccessRoles, [AccessRolesConst.admin_service]: true });
    setIsOpen(false);
    return;
  }
  setRoles({ ...roles, [arr]: true });
  return;
};

// remove to roles
export const removeFromArr = (arr, roles, setRoles) => {
  setRoles({ ...roles, [arr]: false });
};

export const handleAdminPercentage = (percetage, user) => {
  if (percetage !== 100) {
    if (
      user.type === "corporateuser" ||
      user.type === "fundmanager" ||
      user.type === "academiauser"
    ) {
      if (user.accessRole[AccessRolesConst.admin_service]) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
};
