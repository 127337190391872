/** @format */

import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Checkbox } from "@mui/material";

const TOOL_NAMES = [
  "",
  "Global Database",
  "Valuation",
  "Financial Insights",
  "Pitchdeck",
  "Partnership",
  "Virtual Events",
  "Market Insights",
  "Recruitment",
  "Network",
  "Settings",
  "Feed",
  "Chats",
  "Notifications",
  "Pricing",
  "Blogs",
  "Others",
];
export default function Platformupdates() {
  const history = useNavigate();

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [expireIn7days, setexpireIn7days] = useState(false);
  const [toolname, settoolname] = useState();
  const [updateDate, setupdateDate] = useState(new Date());

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!toolname) return toast.warning("Select tool");
    if (!confirm("Are you sure you want to upload this platform update?")) {
      return;
    }
    const data = {
      toolname,
      subject,
      description,
      updateDate,
      expireIn7days,
    };
    await Axios.post("/api/adminlatest/platformupdate", data)
      .then((res) => {
        if (res.data.success) {
          getPlatformUpdates();
          toast.success("Published");
          setSubject("");
          settoolname(0);
          setDescription("");
          setexpireIn7days(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const [platformupdates, setplatformupdates] = useState([]);

  const getPlatformUpdates = async () => {
    try {
      const response = await axios.get("/api/adminlatest/platformupdates");
      if (response.data.success) {
        setplatformupdates(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlatformUpdates();
  }, []);
  const handleDeleteUpdate = async (id) => {
    try {
      const response = await axios.get(
        `/api/adminlatest/deleteplatformupdate/${id}`
      );
      if (response.data.success) {
        toast.success("Delete Success");
        getPlatformUpdates();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <ToastContainer />
      <br />
      <br />

      <p
        className="p_small_white p_button"
        onClick={() => history("/zr-admin-dashboard")}
      >
        {" "}
        &nbsp;&nbsp;&nbsp;&nbsp;Back to dashboard
      </p>
      <div className="auth_root">
        <div className="container auth_container">
          <div className="auth_header">
            <h1 className="h2_white_30">Platform Updates</h1>
          </div>
          <div className="auth_form mb-5">
            <form onSubmit={handleOnSubmit}>
              <div className="row no-margin justify-content-space-evenly">
                <div className="no-left-padding">
                  <label htmlFor="noofemp" className="white">
                    Tool name
                  </label>
                  <select
                    className="body2"
                    name="employees"
                    value={toolname}
                    onChange={(e) => settoolname(e.target.value)}
                    required
                  >
                    <option className="" value={0}>
                      Select Tool
                    </option>
                    <option className="" value={1}>
                      Global Database
                    </option>
                    <option className="" value={2}>
                      Valuation
                    </option>
                    <option className="" value={3}>
                      Financial Insights
                    </option>
                    <option className="" value={4}>
                      Pitchdeck
                    </option>
                    <option className="" value={5}>
                      Partnership
                    </option>
                    <option className="" value={6}>
                      Virtual Events
                    </option>
                    <option className="" value={7}>
                      Market Insights
                    </option>
                    <option className="" value={8}>
                      Recruitment
                    </option>
                    <option className="" value={9}>
                      Network
                    </option>
                    <option className="" value={10}>
                      Settings
                    </option>
                    <option className="" value={11}>
                      Feed
                    </option>
                    <option className="" value={12}>
                      Chats
                    </option>
                    <option className="" value={13}>
                      Notifications
                    </option>
                    <option className="" value={14}>
                      Pricing
                    </option>
                    <option className="" value={15}>
                      Blogs
                    </option>
                    <option className="" value={16}>
                      Others
                    </option>
                  </select>
                </div>
                <div className="no-left-padding">
                  <input
                    id="Subject"
                    placeholder="Subject"
                    onChange={(e) => setSubject(e.target.value)}
                    required
                    type="text"
                    className="auth_input"
                    value={subject}
                  />
                </div>
                <div className="no-left-padding">
                  <textarea
                    id="Description"
                    placeholder="Description"
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    style={{ height: "100px" }}
                    type="text"
                    className="auth_input"
                    value={description}
                  />
                </div>
                <div
                  className="no-left-padding"
                  style={{ backgroundColor: "white" }}
                >
                  <label>Update Date</label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      style={{ color: "white" }}
                      value={updateDate}
                      onChange={(newValue) => {
                        setupdateDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="auth_input"
                          variant="outlined"
                          label="Update Date"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="no-left-padding mt-24">
                  <Checkbox
                    checked={expireIn7days}
                    onChange={(e) => setexpireIn7days(e.target.checked)}
                    sx={{
                      color: "#CACACA",
                      "&.Mui-checked": {
                        color: "#000",
                      },
                    }}
                  />
                  <label htmlFor="term" className="body2 ml-16 white">
                    By selecting, this notification won&apos;t appear after 7
                    days of update date
                  </label>
                </div>
              </div>

              <div className="loginbtns">
                <button className="auth_CTA gradient_CTA" type="submit">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="auth_root">
        <div className="container auth_container">
          <div className="auth_header">
            <h1 className="h2_white_30">Current Live Updates</h1>
          </div>
          {platformupdates.map((v, i) => {
            return (
              <div className="white" key={i}>
                <p>Tool : {TOOL_NAMES[v.toolname]}</p>
                <p> Subject:{v.subject} </p>
                <p>Description:{v.description}</p>
                <p>Update Date : {new Date(v.updateDate).toDateString()}</p>
                <button
                  onClick={() => handleDeleteUpdate(v._id)}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Delete
                </button>
                <br />
                <br />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
