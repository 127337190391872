/** @format */

import { Notyf } from "notyf";
import React, { useState } from "react";
import Axios from "axios";
import { Form } from "react-bootstrap";
export default function Graphics() {
  const [industry, setindustry] = useState("EdTech");
  const [template, setTemplate] = useState("");

  const [graphicsUrl, setGraphicsUrl] = useState("");
  const notyf = new Notyf();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!industry) {
      notyf.error("select industry");
    } else {
      await Axios({
        method: "post",
        url: `/api/pitchdeck/saveGraphic/${industry}`,
        data: { industry: industry, graphicsUrl: graphicsUrl },
        //  template: template },
      })
        .then(() => {
          notyf.success("Uploaded");
          setGraphicsUrl(null);
        })
        .catch(() => {
          notyf.error("Error in upload");
        });
    }
  };

  const handleGraphicUpload = async (e) => {
    const image = e.target.files[0];
    const formdata = new FormData();
    formdata.append("Graphics", image);

    await Axios({
      method: "post",
      url: `/api/pitchdeck/graphics/${template}`,

      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        if (data) {
          setGraphicsUrl(data.graphics);
          // alert("Media Uploaded");
        }
      })
      .catch(() => {
        alert("Error in upload");
      });
  };

  return (
    <div className='container mt-5'>
      <div className='text-left'>
        <h4 className="h_white_20">Upload Graphics</h4>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <label className="h_white_20">Choose Industry &nbsp;</label>
            <select
              value={industry}
              onChange={(e) => setindustry(e.target.value)}>
              <option>FinTech</option>
              <option>EdTech</option>
              <option>GreenTech</option>
              <option>RetailTech</option>
              <option>TravelTech</option>
              <option>FoodTech</option>
            </select>
          </Form.Group>
          <Form.Group>
            <label className="h_white_20">Template &nbsp;</label>
            <input
              type='text'
              id='Template'
              name='Template'
              value={template}
              onChange={(e) => setTemplate(e.target.value)}
            />
          </Form.Group>
          <div style={{ margin: "20px" }}>
            {graphicsUrl ? (
              <>
                <img
                  src={graphicsUrl}
                  alt='graphics'
                  height='200px'
                  width='200px'
                />
                <button
                  style={{ marginLeft: "20px" }}
                  className='btn btn-danger'
                  type='button'
                  onClick={() => setGraphicsUrl(null)}>
                  New
                </button>
              </>
            ) : (
              <>
                <Form.Control
                  type='file'
                  accept='Image/*'
                  id='Graphics'
                  name='Graphics'
                  onChange={(e)=>handleGraphicUpload(e)}
                />
              </>
            )}
          </div>
          <button className='btn btn-success' type='submit'>
            Save
          </button>
        </Form>
      </div>
    </div>
  );
}
